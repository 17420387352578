import { PrimaryButton, Stack, TextField } from "@fluentui/react";
import { Card } from "antd";
import QRCode from "qrcode.react";
import { useState } from "react";

import { Container, Section } from "./styles";

import { PaymentMethodType } from "@/common/types/PaymentMethod.types";

interface PaymentAreaProps {
  paymentType: PaymentMethodType;
}

export const PaymentArea = ({ paymentType }: PaymentAreaProps) => {
  const [copyCode, setCopyCode] = useState("Copiar Código");

  const CODE =
    "00020126360014br.gov.bcb.pix0114273896980001355204000053039865802BR5925INTERSTAR SERVICOS EM TEL6009Paraguacu610937120-000621505110898889561463045646";

  const handleCopyCode = () => {
    setCopyCode("Código copiado!");
  };

  const handleOpenChat = () => {
    window.fcWidget.open();
  };

  const renderPaymentSection = (
    type: PaymentMethodType
  ): JSX.Element | null => {
    switch (type) {
      case PaymentMethodType.PIX:
        return (
          <Section>
            <strong>Código Pix para pagamento!</strong>

            <p style={{ marginBottom: "0.15rem" }}>
              Encaminhe para o cooperado através de mensagem ou email.
            </p>

            <QRCode value={CODE} style={{ marginTop: "1rem" }} />

            <TextField
              styles={{ root: { width: 300 } }}
              label="Código Pix"
              disabled={true}
              readOnly={true}
              defaultValue={CODE}
            />

            <PrimaryButton
              text={copyCode}
              onClick={() => handleCopyCode()}
              style={{ marginTop: "0.85rem" }}
            />
          </Section>
        );
      case PaymentMethodType.CREDIT_CARD:
        return (
          <Section>
            <h2 style={{ fontSize: "16px" }}>
              Solicite o link de pagamento via cartão de crédito em nossa
              central de atendimento.
            </h2>
            <PrimaryButton
              text="Abrir Chat"
              onClick={handleOpenChat}
              style={{ marginTop: "10px" }}
            />
          </Section>
        );
      default:
        return null;
    }
  };

  return (
    <Stack>
      <Card style={{ borderRadius: "0.75rem" }}>
        <Container>
          <h1>Área de Pagamento:</h1>
        </Container>

        <Container>{renderPaymentSection(paymentType)}</Container>
      </Card>
    </Stack>
  );
};
