import { ColumnActionsMode, getTheme, IColumn } from "@fluentui/react";
import { Typography } from "antd";

import TagPicture from "./components/ItemReplacement/Tag/TagPicture";
import ProductListItem from "./Step3/ProductListItem";
import { OverrideValue } from "./Step3/ProductListItem/styles";

import { Badge } from "@/modules/Contracts/styles";
import { ServicesCategoriesTypesEnum } from "@/modules/Settings/types/ServicesCategories.types";
import { compareObjects } from "@/utils/CompareObjects";
import { getNestedObject } from "@/utils/GetNestedObject";
import { parseStringToFloatDoubleFixed } from "@/utils/ParseStringToFloatDoubleFixed";

const theme = getTheme();

function phoneNumberFormat(number: string) {
  number = number.replace(/(\d{2})(\d{5})/, "($1) $2-");
  return number;
}

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}

function additionalIsPersonalized(additionalContractItems) {
  for (const additional of additionalContractItems) {
    const additionalContractItem = {
      bonus: Number(additional?.bonusOverride).toFixed(2),
      activationValue: parseStringToFloatDoubleFixed(
        additional?.activationValueOverride?.toString()
      ),
      monthlyFee: parseStringToFloatDoubleFixed(
        additional?.monthlyFeeOverride?.toString()
      )
    };

    const additionalOfferPrice = {
      bonus: Number(additional.additionalOfferPrice?.bonus).toFixed(2),
      activationValue: parseStringToFloatDoubleFixed(
        additional.additionalOfferPrice?.activationValue?.toString()
      ),
      monthlyFee: parseStringToFloatDoubleFixed(
        additional.additionalOfferPrice?.monthlyFee?.toString()
      )
    };

    const additionalIsEqual = compareObjects(
      additionalContractItem,
      additionalOfferPrice
    );

    if (!additionalIsEqual) {
      return true;
    }
  }
}

export function copyAndSortProducts<T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const properties = columnKey.split("|");

  return items.slice(0).sort((a: T, b: T) => {
    let valueA;
    let valueB;

    if (properties.length > 1) {
      const termA = properties.find(prop =>
        getNestedObject(a as any, prop.split("."))
      );

      const termB = properties.find(prop =>
        getNestedObject(b as any, prop.split("."))
      );

      termA && (valueA = getNestedObject(a as any, termA.split(".")));
      termB && (valueB = getNestedObject(b as any, termB.split(".")));
    } else {
      valueA = getNestedObject(a as any, columnKey.split("."));
      valueB = getNestedObject(b as any, columnKey.split("."));
    }

    valueA = isNaN(valueA) ? valueA : Number(valueA);
    valueB = isNaN(valueB) ? valueB : Number(valueB);

    return (isSortedDescending ? valueA < valueB : valueA > valueB) ? 1 : -1;
  });
}

const checkCustomBenefits = item =>
  (item.offerOverride &&
    Object.entries(item.offerOverride).length &&
    !(
      Object.entries(item.offerOverride).length === 1 &&
      item.offerOverride?.sim === "0"
    )) ||
  (item?.additionalContractItems &&
    additionalIsPersonalized(item.additionalContractItems));

export function buildColumns({
  isCompactMode,
  onColumnClick,
  hasAction,
  serviceCategoryType
}) {
  let columns: IColumn[] = [];
  let compactColumns: IColumn[] = [];

  if (serviceCategoryType !== ServicesCategoriesTypesEnum.TAG) {
    columns = [
      {
        key: "line",
        name: "Linha",
        minWidth: 150,
        maxWidth: 200,
        fieldName: "line",
        isRowHeader: true,
        isMultiline: true,
        data: "string",
        onRender: item => {
          const chipInventory =
            item?.inventory.find(
              iv => iv?.product?.category?.code === "CHIP"
            ) ?? null;

          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              {checkCustomBenefits(item) && (
                <div>
                  <Badge bgColor={theme.semanticColors.severeWarningIcon}>
                    Pacote benefícios personalizada
                  </Badge>
                </div>
              )}
              <div style={{ fontWeight: 600, fontSize: "1.1rem" }}>
                <strong>{phoneNumberFormat(String(item.line.number))}</strong>
              </div>
              <div style={{ marginTop: "5px" }}>
                {item.operation.name} -{" "}
                {item.offerPrice.offer.plan.carrier.name}
              </div>

              {item?.additionalInfo?.iccid && (
                <div
                  style={{
                    fontWeight: 600,
                    marginTop: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <strong>ICCID ORIGINAL:</strong>
                  <span className="ms-fontWeight-regular">
                    {item?.additionalInfo?.iccid}
                  </span>
                </div>
              )}
              {!item?.additionalInfo?.iccid && !!chipInventory && (
                <div
                  style={{
                    fontWeight: 600,
                    marginTop: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <strong>ICCID:</strong>
                  <span className="ms-fontWeight-regular">
                    {chipInventory?.serialNumber && chipInventory?.serialNumber}
                  </span>
                </div>
              )}
            </div>
          );
        },
        isPadded: true
      },
      {
        key: "description",
        name: "Descrição",
        minWidth: 400,
        fieldName: "description",
        isRowHeader: true,
        data: "string",
        isPadded: true,
        isMultiline: true,
        onRender: item => <ProductListItem item={item} />
      }
    ];

    compactColumns = [
      {
        key: "line",
        name: "Linha",
        fieldName: "line",
        minWidth: 100,
        maxWidth: 120,
        isRowHeader: true,
        data: "string",
        isPadded: true,
        onRender: ({ line: { number } }) => phoneNumberFormat(String(number))
      },
      {
        key: "operation",
        name: "Operação",
        fieldName: "operation.name",
        isSorted: true,
        isSortedDescending: false,
        onColumnClick: onColumnClick,
        minWidth: 100,
        maxWidth: 100,
        isPadded: true,
        isMultiline: true,
        onRender: ({ operation }) => operation?.name
      },
      {
        key: "carrier",
        name: "Operadora",
        fieldName: "offerPrice.offer.plan.carrier",
        isSorted: true,
        onColumnClick: onColumnClick,
        minWidth: 100,
        maxWidth: 100,
        isPadded: true,
        isMultiline: true,
        onRender: ({
          offerPrice: {
            offer: {
              plan: { carrier }
            }
          }
        }) => carrier.name
      },
      {
        key: "plan",
        name: "Plano de benefícios",
        fieldName: "offerPrice.offer.plan.name",
        isSorted: true,
        onColumnClick: onColumnClick,
        minWidth: 100,
        isPadded: true,
        isMultiline: true,
        onRender: ({
          offerPrice: {
            offer: { plan }
          }
        }) => plan.name
      },
      {
        key: "planPrice",
        name: "Valor do plano de benefícios",
        fieldName: "offerOverride.price|offerPrice.price",
        isSorted: true,
        onColumnClick: onColumnClick,
        minWidth: 100,
        maxWidth: 100,
        isPadded: true,
        isMultiline: true,
        onRender: ({ offerPrice: { price }, offerOverride }) => {
          const monthlyAdditionalValueSum = 100;
          if (offerOverride?.price) {
            return (
              toLocaleString(offerOverride.price) + monthlyAdditionalValueSum
            );
          }
          return toLocaleString(price);
        }
      },
      {
        key: "discount",
        name: "Descontos",
        fieldName: "offerOverride.discount|offerPrice.offer.discount.value",
        isSorted: true,
        onColumnClick: onColumnClick,
        minWidth: 100,
        maxWidth: 100,
        isPadded: true,
        isMultiline: true,
        onRender: ({
          offerPrice: {
            offer: { discount }
          },
          offerOverride
        }) => {
          if (offerOverride?.discount)
            return toLocaleString(offerOverride.discount);

          if (discount?.value) return toLocaleString(discount.value);
        }
      },
      {
        key: "device",
        name: "Aparelho",
        fieldName: "inventory.product?.name",
        isSorted: true,
        onColumnClick: onColumnClick,
        minWidth: 100,
        maxWidth: 100,
        isPadded: true,
        isMultiline: true,
        onRender: ({ inventory }) => inventory?.product?.name ?? "-"
      },
      {
        key: "priceValue",
        name: "Valor do aparelho",
        fieldName: "offerPrice.salesConditions.cashValue",
        isSorted: true,
        onColumnClick: onColumnClick,
        minWidth: 100,
        isPadded: true,
        isMultiline: true,
        onRender: ({ saleCondition, offerOverride }) => {
          if (offerOverride?.cashValue)
            return toLocaleString(offerOverride.cashValue);

          if (saleCondition?.cashValue)
            return toLocaleString(saleCondition?.cashValue);

          return "-";
        }
      }
    ];
  }

  if (serviceCategoryType === ServicesCategoriesTypesEnum.TAG) {
    columns = [
      {
        key: "tag",
        name: "Tag",
        fieldName: "inventory.serialNumber",
        minWidth: 300,
        maxWidth: 300,
        onColumnClick: onColumnClick,
        onRender: item =>
          item.inventory
            .filter(iv => iv?.product?.category?.code === "TAG")
            .map(iv => (
              <TagPicture key={iv.id} serialNumber={iv.serialNumber} />
            ))
      },
      {
        key: "vehicle",
        name: "Veículo",
        fieldName: "additionalInfo.vehicleLicensePlate",
        minWidth: 200,
        onColumnClick: onColumnClick,
        onRender: item => (
          <Typography>
            <p style={{ marginBottom: 8 }}>
              <strong>Placa:</strong>{" "}
              {item.additionalInfo.vehicleLicensePlate.replace(
                /(.{3})(.)/,
                "$1-$2"
              )}
            </p>
            <p style={{ marginBottom: 0 }}>
              <strong>Tipo:</strong>{" "}
              {item.additionalInfo.vehicleType.description}
            </p>
          </Typography>
        ),
        isMultiline: true,
        isRowHeader: true
      },
      {
        key: "operation",
        name: "Operação",
        fieldName: "operation.name",
        minWidth: 120,
        onColumnClick: onColumnClick,
        onRender: item => item?.operation?.name,
        isRowHeader: true,
        isMultiline: true
      },
      {
        key: "plan",
        name: "Plano de benefícios",
        fieldName: "offerPrice.offer.plan.name",
        minWidth: 120,
        onColumnClick: onColumnClick,
        onRender: item => item.offerPrice.offer.plan.name,
        isRowHeader: true,
        isMultiline: true
      },
      {
        key: "monthlyPrice",
        name: "Valor do plano de benefícios",
        fieldName: "offerPrice.price",
        minWidth: 100,
        onColumnClick: onColumnClick,
        onRender: ({ offerPrice: { price } }) => `${toLocaleString(price)}/mês`,
        isRowHeader: true,
        isMultiline: true
      },
      {
        key: "priceValue",
        name: "Valor da Tag",
        fieldName: "offerPrice.salesConditions.cashValue",
        minWidth: 80,
        onColumnClick: onColumnClick,
        isRowHeader: true,
        onRender: ({ saleCondition }) => {
          if (saleCondition?.cashValue)
            return toLocaleString(saleCondition?.cashValue);

          return "-";
        }
      },
      {
        key: "canceledAtStartag",
        name: "Cancelado",
        fieldName: "canceledAtStartag",
        minWidth: 120,
        onColumnClick: onColumnClick,
        styles: {
          root: {}
        },
        onRender: item => {
          return item.inventory.map(value => {
            if (value?.canceledAtStartag) {
              const canceledAtStartag = new Intl.DateTimeFormat("pt-BR").format(
                new Date(value.canceledAtStartag)
              );
              return (
                <OverrideValue theme={theme}>{canceledAtStartag}</OverrideValue>
              );
            } else {
              return "-";
            }
          });
        },
        isRowHeader: false,
        isMultiline: true
      }
    ];

    compactColumns = [
      {
        key: "tag",
        name: "Número de série",
        fieldName: "inventory.serialNumber",
        minWidth: 150,
        maxWidth: 150,
        onColumnClick: onColumnClick,
        isRowHeader: true,
        onRender: item =>
          item.inventory
            .filter(iv => iv?.product?.category?.code === "TAG")
            .map(iv => iv.serialNumber.match(/.{1,4}/g).join(" "))
      },
      {
        key: "vehicleLicensePlate",
        name: "Placa do veículo",
        fieldName: "additionalInfo.vehicleLicensePlate",
        minWidth: 110,
        maxWidth: 110,
        onColumnClick: onColumnClick,
        onRender: item =>
          item.additionalInfo.vehicleLicensePlate.replace(/(.{3})(.)/, "$1-$2"),
        isRowHeader: true
      },
      {
        key: "vehicleType",
        name: "Tipo de veículo",
        fieldName: "additionalInfo.vehicleType",
        minWidth: 250,
        onColumnClick: onColumnClick,
        onRender: item => item.additionalInfo.vehicleType.description,
        isRowHeader: true,
        isMultiline: true
      },
      {
        key: "operation",
        name: "Operação",
        fieldName: "operation.name",
        minWidth: 120,
        onColumnClick: onColumnClick,
        onRender: item => item?.operation?.name,
        isRowHeader: true,
        isMultiline: true
      },
      {
        key: "plan",
        name: "Plano de benefícios",
        fieldName: "offerPrice.offer.plan.name",
        minWidth: 120,
        onColumnClick: onColumnClick,
        onRender: item => item.offerPrice.offer.plan.name,
        isRowHeader: true,
        isMultiline: true
      },
      {
        key: "monthlyPrice",
        name: "Valor do plano de benefícios",
        fieldName: "offerPrice.price",
        minWidth: 100,
        onColumnClick: onColumnClick,
        onRender: ({ offerPrice: { price } }) => `${toLocaleString(price)}/mês`,
        isRowHeader: true,
        isMultiline: true
      },
      {
        key: "priceValue",
        name: "Valor da Tag",
        fieldName: "offerPrice.salesConditions.cashValue",
        minWidth: 80,
        isRowHeader: true,
        onColumnClick: onColumnClick,
        onRender: ({ saleCondition }) => {
          if (saleCondition?.cashValue)
            return toLocaleString(saleCondition?.cashValue);

          return "-";
        }
      },
      {
        key: "canceledAtStartag",
        name: "Cancelado",
        fieldName: "canceledAtStartag",
        minWidth: 120,
        onColumnClick: onColumnClick,
        styles: {
          root: {}
        },
        onRender: item => {
          return item.inventory.map(value => {
            if (value.canceledAtStartag) {
              const canceledAtStartag = new Intl.DateTimeFormat("pt-BR").format(
                new Date(value.canceledAtStartag)
              );
              return (
                <OverrideValue theme={theme}>{canceledAtStartag}</OverrideValue>
              );
            } else {
              return "-";
            }
          });
        },
        isRowHeader: false,
        isMultiline: true
      }
    ];
  }

  const actions = {
    key: "actions",
    name: "actions",
    fieldName: "actions",
    minWidth: 40,
    maxWidth: 40,
    columnActionsMode: ColumnActionsMode.disabled,
    isIconOnly: true
  };

  if (isCompactMode)
    return hasAction ? [...compactColumns, actions] : compactColumns;

  return hasAction ? [...columns, actions] : columns;
}
