import { Stack } from "@fluentui/react";
import styled from "styled-components";

export const ImageLogo = styled.img`
  margin-bottom: 20px;
  height: 30px;
`;

export const CardContainer = styled(Stack.Item)`
  background: #ffffff;
  box-sizing: border-box;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
`;
