import {
  DirectionalHint,
  IconButton,
  mergeStyleSets,
  Modal,
  Stack
} from "@fluentui/react";
import { Badge } from "antd";
import { useState } from "react";

import { PlanButton } from "../PlansOffers/CardPlan/styles";

import { CardContainer, ImageLogo } from "./styles";
import { TagOfferContract } from "./TagOfferContract";

import { OffersToolTip } from "@/components/Shared/OffersToolTip/OffersToolTip";
import { Plan } from "@/modules/Offers/types/PlansOffers.types";
import { ListOfTagOffers } from "@/modules/Offers/types/TagOffers.types";

interface TagOffersProps {
  tags: ListOfTagOffers;
}

function TagOffers({ tags }: TagOffersProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>(undefined);

  const toggleOpenModal = () => {
    setIsModalOpen(prevState => {
      return !prevState;
    });
  };

  return (
    <>
      <h1
        className="ms-fontWeight-semibold ms-fontSize-24"
        style={{ paddingTop: 10 }}
      >
        Pacote de Benefícios Tag
      </h1>

      <CardContainer
        grow
        className="ms-depth-16 ms-sm6 ms-md4 ms-lg3 ms-xl2"
        align="center"
      >
        <ImageLogo src={tags.logo} alt={tags.name} />

        <Stack
          horizontal
          tokens={{ childrenGap: 20 }}
          style={{ width: "100%" }}
          wrap
          horizontalAlign="center"
          verticalAlign="center"
        >
          {tags?.plans?.map((planItem, index) =>
            planItem.name
              .toLowerCase()
              .normalize("NFD")
              .replace("-", " ")
              .replace(/[\u0300-\u036f]/g, "")
              .search(/pre pago/i) !== -1 ? (
              <Badge.Ribbon
                key={`badge-plan-${planItem.id}`}
                text="Pré Pago"
                color="#00ad56"
                style={{
                  top: "-15px",
                  textTransform: "uppercase",
                  fontSize: 12,
                  fontWeight: "bold"
                }}
              >
                <OffersToolTip
                  plan={planItem}
                  position={
                    index % 2 === 0
                      ? DirectionalHint.leftCenter
                      : DirectionalHint.rightCenter
                  }
                >
                  <PlanButton
                    key={planItem.id}
                    isActive={planItem.id === selectedPlan?.id}
                    onClick={() => {
                      setSelectedPlan(planItem);
                      setIsModalOpen(true);
                    }}
                    minWidth="100px"
                    width="100%"
                  >
                    {planItem.name}
                  </PlanButton>
                </OffersToolTip>
              </Badge.Ribbon>
            ) : (
              <div key={planItem.id} style={{ width: "40%" }}>
                <OffersToolTip
                  plan={planItem}
                  position={
                    index % 2 === 0
                      ? DirectionalHint.leftCenter
                      : DirectionalHint.rightCenter
                  }
                >
                  <PlanButton
                    key={planItem.id}
                    minWidth="100px"
                    width="100%"
                    isActive={planItem.id === selectedPlan?.id}
                    onClick={() => {
                      setSelectedPlan(planItem);
                      setIsModalOpen(true);
                    }}
                  >
                    {planItem.name}
                  </PlanButton>
                </OffersToolTip>
              </div>
            )
          )}
        </Stack>
      </CardContainer>

      <Modal
        titleAriaId="Tag Contract Offer"
        isOpen={isModalOpen}
        onDismiss={toggleOpenModal}
        onDismissed={() => {
          setSelectedPlan(undefined);
        }}
        isBlocking={false}
        containerClassName={contentStyles.container}
        scrollableContentClassName={scrollableStyles.container}
      >
        <IconButton
          styles={{
            root: {
              zIndex: 1,
              position: "absolute",
              top: 10,
              right: 10
            }
          }}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Close popup modal"
          onClick={toggleOpenModal}
        />
        <TagOfferContract selectedPlan={selectedPlan} selectedCarrier={tags} />
      </Modal>
    </>
  );
}

export default TagOffers;

const scrollableStyles = mergeStyleSets({
  container: {
    display: "flex"
  }
});

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch"
  }
});
