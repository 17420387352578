import {
  MembershipStatusEnum,
  MembershipStatusTranslator
} from "../types/MembershipPage.types";

import { FiltersProps } from "@/core/libs/list-data/lib/types";
import { AccountType, AccountTypeTranslator } from "@/modules/Accounts/types";

function removeMask(value: string) {
  return value.replace(/[^\d%]/g, "");
}

export const buildMembersFilters = (
  disableAccountTypeFilter: boolean
): FiltersProps[] => {
  return [
    {
      label: "Nome",
      placeholder: "Nome",
      filterQuery: "fullName",
      type: "text",
      maxWidth: "200px"
    },
    {
      label: "Tipo",
      placeholder: "Tipo",
      type: "select",
      disabled: disableAccountTypeFilter,
      filterQuery: "accountType",
      options: [
        { key: AccountType.LEGAL, text: AccountTypeTranslator.LEGAL },
        { key: AccountType.PHYSICAL, text: AccountTypeTranslator.PHYSICAL }
      ],
      minWidth: "150px",
      maxWidth: "150px"
    },
    {
      label: "CPF/CNPJ",
      placeholder: "Filtrar Documentos",
      dialogTitle: "Adicione uma ou mais documentos abaixo",
      dialogSubText:
        "Separe os documentos usando vírgula ou quebra de linha. Em caso de busca em massa, os documentos devem ser informados na forma exata do cadastro.",
      successDescription: "Documentos adicionadas",
      boxTitle: "Adicionar documentos ao filtro",
      filterQuery: "documentNumbers",
      type: "arrayText",
      maskFormatter: removeMask
    },
    {
      label: "Nome Unidade de Negocio",
      placeholder: "Unidade de Negocio",
      filterQuery: "businessUnitName",
      type: "text",
      maxWidth: "200px"
    },
    {
      label: "Adesão do membro",
      placeholder: "Status",
      filterQuery: "membershipStatus",
      type: "select",
      options: [
        {
          key: MembershipStatusEnum.AWAITING_SIGNATURE,
          text: MembershipStatusTranslator.AWAITING_SIGNATURE
        },
        {
          key: MembershipStatusEnum.DISSOCIATED,
          text: MembershipStatusTranslator.DISSOCIATED
        },
        {
          key: MembershipStatusEnum.EXPIRED,
          text: MembershipStatusTranslator.EXPIRED
        },
        {
          key: MembershipStatusEnum.REFUSED,
          text: MembershipStatusTranslator.REFUSED
        },
        {
          key: MembershipStatusEnum.SIGNED,
          text: MembershipStatusTranslator.SIGNED
        },
        {
          key: MembershipStatusEnum.NOT_ASSOCIATED,
          text: MembershipStatusTranslator.NOT_ASSOCIATED
        },
        {
          key: MembershipStatusEnum.INTEGRATED,
          text: MembershipStatusTranslator.INTEGRATED
        },
        {
          key: MembershipStatusEnum.INVOICE_CHARGED,
          text: MembershipStatusTranslator.INVOICE_CHARGED
        }
      ],
      minWidth: "150px",
      maxWidth: "150px"
    }
  ];
};
