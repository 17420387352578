import { Stack } from "@fluentui/react";
import styled from "styled-components";

export const CardContainer = styled(Stack.Item)`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 10px;
  max-width: 350px;
  min-width: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 10px;
`;
