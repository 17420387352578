import { addYears } from "@fluentui/react";

import {
  AccountContactType,
  AccountDocumentType,
  GenderStatus
} from "../types/Accounts.types";

import { formatAccountFields } from "./utils/formatAccountFields";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";
import { PermissionsFormat } from "@/common/types/PermissionsFormat.types";
import { checkSicoobAccount } from "@/utils/CheckSicoobAccount";

type FieldsProps = {
  initialValues?: any;
  hasDefaultValue?: any;
  isEditField?: boolean;
  bankNumberValue?: string;
  agencyNumberValue?: string;
  bankAccountRequired?: boolean;
  maritalStatusOptions: Array<{ key: number; text: string }>;
  salesTeamList: Array<{ key: number; text: string }>;
  permissions: PermissionsFormat;
  whatsappRequired?: boolean;
  states: Array<{ key: string; text: string }>;
  cities: Array<{ key: string; text: string }>;
  executeOnChange?: (value: unknown) => void;
};

const today = new Date();
const minDate = addYears(today, -120);
const maxDate = addYears(today, -16);

const buildPhysicalAccountCheckingAccount = ({
  values,
  isRequired,
  object
}) => {
  if (values?.length > 0) {
    return values;
  } else if (isRequired) {
    return object;
  }
  return [];
};

export const fields = {
  common({
    initialValues,
    isEditField,
    bankNumberValue,
    agencyNumberValue,
    bankAccountRequired,
    maritalStatusOptions,
    salesTeamList,
    permissions,
    whatsappRequired,
    cities,
    states,
    executeOnChange
  }: FieldsProps) {
    const stateFound = states?.find(
      state => state.text == initialValues?.naturalnessState
    );

    const naturalness = {
      state: stateFound?.key,
      city: initialValues?.naturalnessCity
    };

    const allFields = [
      {
        name: "groupedFields",
        key: "initialDataGroupedFields",
        fields: [
          {
            label: "Dados Iniciais",
            type: "arrayField",
            name: "account",
            value: initialValues?.account || [
              {
                fullName: initialValues?.fullName,
                birthDate: initialValues?.birthDate,
                genderStatus: initialValues?.genderStatus,
                maritalStatusId: initialValues?.maritalStatusId,
                occupation: initialValues?.occupation,
                spouse: initialValues?.spouse,
                numberOfChildren: initialValues?.numberOfChildren,
                nationality: initialValues?.nationality
              }
            ],

            fields: [
              {
                label: "Nome Completo",
                placeholder: "Nome Completo",
                type: "text",
                name: "fullName",
                value: initialValues?.fullName || "",
                disabled: isEditField,
                required: !isEditField,
                customValidation: value => {
                  if (value) {
                    value = value.trim();
                    if (value.split(" ").length >= 2) {
                      return { success: true, message: null };
                    } else {
                      return {
                        success: false,
                        message: "Nome deve ser composto de nome e sobrenome"
                      };
                    }
                  }
                  return { success: true, message: null };
                }
              },
              {
                label: "Data de Nascimento",
                placeholder: "____/____/______",
                type: "date",
                name: "birthDate",
                value: initialValues?.birthDate || "",
                required: true,
                size: 4,
                invalidDateErrorMessage: `A data deve ser entre os anos ${minDate.getFullYear()} e ${maxDate.getFullYear()}`,
                maxDate: maxDate,
                minDate: minDate
              },
              {
                label: "Gênero",
                placeholder: "Selecione o Gênero",
                type: "select",
                name: "genderStatus",
                key: "genderStatus",
                value: initialValues?.genderStatus || "",
                defaultValues: "",
                required: false,
                options: [
                  { key: GenderStatus.FEMALE, text: "Feminino" },
                  { key: GenderStatus.MALE, text: "Masculino" },
                  { key: GenderStatus.OTHER, text: "Outro" },
                  {
                    key: GenderStatus.RATHER_NOT_SAY,
                    text: "Prefere não informar"
                  }
                ]
              },
              {
                label: "Estado Civil",
                placeholder: "Selecione o Estado Civil",
                type: "select",
                name: "maritalStatusId",
                key: "maritalStatusId",
                required: false,
                value: initialValues?.maritalStatusId || "",
                options: maritalStatusOptions ?? []
              },
              {
                label: "Cônjuge",
                placeholder: "Cônjuge",
                type: "text",
                name: "spouse",
                key: "spouse",
                value: initialValues?.spouse || "",
                required: false
              },
              {
                label: "Número de filhos",
                placeholder: "Número de filhos",
                type: "number",
                name: "numberOfChildren",
                key: "numberOfChildren",
                value: initialValues?.numberOfChildren || "",
                required: false
              },
              {
                label: "Ocupação",
                placeholder:
                  "Ex.: Gerente, Desenvolvedor, Assistente, Lojista, Professor",
                type: "text",
                name: "occupation",
                key: "occupation",
                value: initialValues?.occupation || "",
                required: true
              },
              {
                label: "Nacionalidade",
                placeholder: "Nacionalidade",
                type: "text",
                name: "nationality",
                key: "nationality",
                value: initialValues?.nationality || "",
                required: false
              }
            ]
          }
        ]
      },

      {
        name: "groupedFields",
        key: "naturalnessGroupedFields",
        fields: [
          {
            label: "Naturalidade",
            type: "arrayField",
            name: "naturalness",
            value: [naturalness],
            fields: [
              {
                label: "Estado",
                placeholder: "Selecione o Estado",
                type: "searchSelectField",
                name: "state",
                dinamicSearchDisabled: true,
                options: states,
                required: false,
                value: stateFound?.key || "",
                executeOnChange
              },
              {
                label: "Cidade",
                placeholder: "Selecione a Cidade",
                type: "searchSelectField",
                dinamicSearchDisabled: true,
                name: "city",
                value: initialValues?.naturalnessCity || "",
                options: cities,
                required: false
              }
            ]
          }
        ]
      },

      {
        name: "groupedFields",
        key: "contactsGroupedFields",
        fields: [
          {
            label: "Contato(s)",
            type: "arrayField",
            name: "contacts",
            minItems: 1,
            newFieldButtonEnabled: true,
            deleteButtonEnabled: true,
            newFieldButtonMessage: "Adicionar novo contato",
            value: initialValues?.contacts
              ? formatAccountFields({
                  values: initialValues?.contacts,
                  requiredFields: [
                    AccountContactType.PHONE_NUMBER,
                    AccountContactType.EMAIL,
                    AccountContactType.WHATSAPP
                  ],
                  fieldKey: "contactType",
                  valueKey: "contact",
                  includeAllValues: true
                })
              : [
                  {
                    contactType: AccountContactType.EMAIL,
                    contact: ""
                  },
                  {
                    contactType: AccountContactType.PHONE_NUMBER,
                    contact: ""
                  }
                ],
            defaultValues: { contactType: "", contact: "" },
            size: 8,
            deleteEnabledOn: (fieldValue, values, idx) => {
              if (whatsappRequired) {
                return false;
              }

              const indexes = [
                values.findIndex(
                  v => v.contactType === AccountContactType.EMAIL
                ),
                values.findIndex(
                  v => v.contactType === AccountContactType.PHONE_NUMBER
                )
              ];

              return (
                !indexes.includes(idx) ||
                (fieldValue.contactType !== AccountContactType.EMAIL &&
                  fieldValue.contactType !== AccountContactType.PHONE_NUMBER)
              );
            },
            autoSelectOnNewField: true,
            showQuantity: true,
            fields: [
              {
                label: "Tipo",
                type: "select",
                required: true,
                name: "contactType",
                size: 4,
                value: "",
                disabledInputOn: (fieldValue, values, idx) => {
                  const indexes = [
                    values.findIndex(
                      v => v.contactType === AccountContactType.EMAIL
                    ),
                    values.findIndex(
                      v => v.contactType === AccountContactType.PHONE_NUMBER
                    ),
                    values.findIndex(
                      v => v.contactType === AccountContactType.WHATSAPP
                    )
                  ];

                  return indexes.includes(idx);
                },
                autoSelectOption: true,
                deleteButtonEnabled: true,
                options: [
                  {
                    key: AccountContactType.PHONE_NUMBER,
                    text: "Telefone"
                  },
                  { key: AccountContactType.EMAIL, text: "E-mail" },
                  { key: AccountContactType.WHATSAPP, text: "WhatsApp" }
                ]
              },
              {
                label: "Contato",
                type: "text",
                name: "contact",
                value: "",
                required: true,
                maskCondition: value => {
                  if (
                    [
                      AccountContactType.PHONE_NUMBER,
                      AccountContactType.WHATSAPP
                    ].includes(value.contactType)
                  ) {
                    return "(**) *****-****";
                  } else {
                    return null;
                  }
                }
              }
            ]
          }
        ]
      },
      {
        name: "groupedFields",
        key: "documentsGroupedFields",
        fields: [
          {
            label: "Documentos",
            type: "arrayField",
            name: "documents",
            newFieldButtonEnabled: true,
            newFieldButtonDisabled: isEditField,
            minItems: 2,
            newFieldButtonMessage: "Adicionar novo documento",
            value: initialValues?.documents
              ? formatAccountFields({
                  values: initialValues?.documents,
                  requiredFields: [
                    AccountDocumentType.RG,
                    AccountDocumentType.CPF
                  ],
                  fieldKey: "documentType",
                  valueKey: "documentNumber",
                  includeAllValues: true
                })
              : [
                  { documentType: AccountDocumentType.CPF, documentNumber: "" },
                  { documentType: AccountDocumentType.RG, documentNumber: "" }
                ],
            defaultValues: {
              documentType: "",
              documentNumber: ""
            },
            deleteEnabledOn: value => {
              return ![
                AccountDocumentType.CPF,
                AccountDocumentType.RG
              ].includes(value.documentType);
            },
            limit: 8,
            fields: [
              {
                label: "Tipo",
                type: "select",
                name: "documentType",
                value: "",
                size: 4,
                minItem: 1,
                autoSelectOption: true,
                disabledInputOn: value => {
                  return [AccountDocumentType.CPF].includes(value.documentType);
                },
                exclusiveOptions: true,
                options: [
                  { key: AccountDocumentType.CPF, text: " CPF" },
                  { key: AccountDocumentType.RG, text: "RG" },
                  { key: AccountDocumentType.CNH, text: "CNH" },
                  { key: AccountDocumentType.PASSPORT, text: "PASSPORT" },
                  { key: AccountDocumentType.PIS_PASEP, text: "PIS PASEP" },
                  {
                    key: AccountDocumentType.CERTIDAO_NASCIMENTO,
                    text: "CERT. NASCIMENTO"
                  },
                  {
                    key: AccountDocumentType.CERTIFICADO_RESERVISTA,
                    text: "CERT. RESERVISTA"
                  },
                  { key: AccountDocumentType.OUTROS, text: "OUTROS" }
                ]
              },
              {
                label: "Número",
                type: "text",
                name: "documentNumber",
                required: true,
                value: "",
                disabledInputOn: (item, values, arrayIndex) => {
                  if (!item?.documentNumber) return false;
                  const hasValue = initialValues?.documents?.find(
                    doc =>
                      doc.documentType ===
                      AccountDocumentType[item.documentType]
                  );

                  return (
                    isEditField &&
                    hasValue &&
                    [AccountDocumentType.CPF].includes(item.documentType)
                  );
                },
                maskCondition: doc => {
                  if (doc.documentType === AccountDocumentType.CPF) {
                    return "***.***.***-**";
                  } else {
                    return null;
                  }
                }
              }
            ]
          }
        ]
      },
      {
        name: "groupedFields",
        key: "addressesGroupedFields",
        fields: [
          {
            label: "Endereço",
            type: "addresses",
            name: "addresses",
            minItems: 1,
            newAddressButtonEnabled: true,
            deleteButtonEnabled: true,
            newFieldButtonMessage: "Adicionar novo endereço",
            value: initialValues?.addresses ?? [
              {
                zipCode: "",
                city: "",
                state: "",
                street: "",
                neighborhood: "",
                number: "",
                note: ""
              }
            ],
            defaultValues: {
              zipCode: "",
              city: "",
              state: "",
              street: "",
              neighborhood: "",
              number: "",
              note: ""
            },

            fields: [
              {
                label: "Código postal",
                type: "zipCode",
                name: "zipCode",
                mask: "99999-999",
                value: "",
                key: "zipCode",
                required: true
              },
              {
                label: "Cidade",
                type: "text",
                name: "city",
                key: "city",
                value: "",
                required: false,
                disabled: true
              },
              {
                label: "Estado",
                type: "text",
                name: "state",
                key: "state",
                value: "",
                mask: "**",
                required: false,
                disabled: true
              },
              {
                label: "Rua",
                type: "text",
                name: "street",
                key: "street",
                value: "",
                required: false
              },
              {
                label: "Bairro",
                type: "text",
                name: "neighborhood",
                key: "neighborhood",
                value: "",
                required: false
              },
              {
                label: "Número",
                type: "string",
                name: "number",
                key: "number",
                value: "",
                required: false,
                disabled: false
              },
              {
                label: "Complemento",
                type: "text",
                name: "note",
                key: "note",
                value: "",
                required: false,
                disabled: false
              }
            ]
          }
        ]
      },
      {
        name: "groupedFields",
        key: "checkingAccountsGroupedFields",
        fields: [
          {
            label: "Dados Bancários",
            type: "arrayField",
            name: "checkingAccounts",
            required: bankAccountRequired,
            minItems: bankAccountRequired ? 1 : 0,
            newFieldButtonEnabled: true,
            deleteButtonEnabled: true,
            newFieldButtonMessage: "Adicionar nova conta",
            value: buildPhysicalAccountCheckingAccount({
              values: initialValues?.checkingAccounts,
              isRequired: bankAccountRequired,
              object: [
                {
                  bankNumber: bankNumberValue || "",
                  agencyNumber: agencyNumberValue || "",
                  accountNumber: ""
                }
              ]
            }),
            defaultValues: {
              bankNumber: bankNumberValue,
              agencyNumber: agencyNumberValue,
              accountNumber: ""
            },

            fields: [
              {
                label: "Banco",
                type: "number",
                name: "bankNumber",
                value: "",
                required: true,
                size: 3,
                disabled: bankNumberValue
              },
              {
                label: "Agência",
                type: "number",
                name: "agencyNumber",
                value: "",
                required: true,
                disabled: agencyNumberValue,
                size: 2,
                maxLength: 4
              },
              {
                label: "Conta",
                type: "number",
                name: "accountNumber",
                value: "",
                size: 3,
                required: true,
                customValidation: (value: string) => {
                  const validated = checkSicoobAccount(value);
                  if (validated) {
                    return { success: true, message: null };
                  } else {
                    return { success: false, message: "Conta Inválida" };
                  }
                }
              }
            ]
          }
        ]
      }
    ];
    if (
      permissions[PermissionsScope.POS]?.[PermissionsPosModule.ACCOUNTS]?.some(
        contractAction =>
          contractAction === PermissionsAction.OVERWRITE_SALES_TEAM
      )
    ) {
      return [
        ...allFields,
        {
          name: "groupedFields",
          key: "salesTeamField",
          fields: [
            {
              label: "Carteira do Cooperado",
              type: "select",
              name: "accountSalesTeam",
              options: salesTeamList,
              size: 4,
              minItem: 1,
              autoSelectOption: true,
              exclusiveOptions: true,
              value: initialValues?.salesTeamId || ""
            }
          ]
        }
      ];
    }
    return allFields;
  },

  create({
    agencyNumberValue,
    bankNumberValue,
    bankAccountRequired,
    initialValues,
    maritalStatusOptions,
    salesTeamList,
    permissions,
    states,
    cities,
    executeOnChange
  }) {
    return [
      ...this.common({
        agencyNumberValue,
        bankNumberValue,
        bankAccountRequired,
        initialValues,
        maritalStatusOptions,
        salesTeamList,
        permissions,
        states,
        cities,
        executeOnChange
      })
    ];
  },

  edit({
    initialValues,
    isEditField,
    agencyNumberValue,
    bankNumberValue,
    bankAccountRequired,
    maritalStatusOptions,
    salesTeamList,
    permissions,
    whatsappRequired,
    states,
    cities,
    executeOnChange
  }) {
    return [
      {
        label: "ID",
        type: "hidden",
        name: "id",
        value: initialValues.id || "",
        disabled: true
      },
      ...this.common({
        initialValues,
        isEditField,
        agencyNumberValue,
        bankNumberValue,
        bankAccountRequired,
        maritalStatusOptions,
        salesTeamList,
        permissions,
        whatsappRequired,
        states,
        cities,
        executeOnChange
      })
    ];
  }
};
