import { Empty, Spin, Timeline, Typography } from "antd";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { ContractsService } from "@/core/libs/api";
import { phoneNumberFormatter } from "@/utils/PhoneNumberFormatter";

const { Text, Paragraph } = Typography;

type ContractOccurrence =
  | ContractOccurrenceCommon
  | ContractOccurrenceStep
  | ContractOccurrenceItem;

interface ContractOccurrenceCommon {
  id: number;
  createdAt: string;
  ipAddress: string;
  logType: ContractsActionsLogsType;
  statusChangeReason: string | null;
  user: User | null;
  contractItem: ContractItem | null;
  currentStep: Step | null;
  nextStep: Step | null;
  upload: Upload | null;
  account: Account | null;
}

interface ContractOccurrenceStep extends ContractOccurrenceCommon {
  currentStep: Step;
}

interface ContractOccurrenceItem extends ContractOccurrenceCommon {
  contractItem: ContractItem;
}

interface ContractOccurrenceUpload extends ContractOccurrenceCommon {
  upload: Upload;
}

interface ContractOccurrenceAccount extends ContractOccurrenceCommon {
  account: Account;
}

interface Upload {
  fileName: string;
}

interface Account {
  id: number;
  fullName: string;
}

interface ContractItem {
  id: number;
  operation: Operation;
  line: Line;
  offerPrice: OfferPrice;
  inventory: Inventory[] | null;
}

interface Inventory {
  serialType: string;
  serialNumber: string;
  product: Product;
}

interface Product {
  name: string;
}

interface Line {
  id: number;
  number: string;
}

interface OfferPrice {
  id: number;
  price: string;
  offer: Offer;
}

interface Offer {
  id: number;
  combo: string;
  plan: Plan;
}

interface Plan {
  id: number;
  name: string;
  carrier: Operation;
}

interface Operation {
  id: number;
  name: string;
}

interface Step {
  id: number;
  internalName: string;
  friendlyName: string;
}

interface User {
  id: number;
  fullName: string;
  email: string;
}

type ContractsActionsLogsType =
  | "CREATED_BY_OFFER"
  | "CREATED"
  | "ACCOUNT_ADDED"
  | "SHIPPING_UPDATED"
  | "SIGNATURES_ADDED"
  | "SIGNATURES_UPDATED"
  | "DOCUMENT_UPLOADED"
  | "DOCUMENT_REMOVED"
  | "ACCOUNT_NOTIFIED"
  | "ITEM_ADDED"
  | "ITEM_REMOVED"
  | "STATUS_CHANGED"
  | "STATUS_CHANGED_BY_DIGITAL_SIGNATURE"
  | "INSTALLMENTS_CALCULATED"
  | "MODIFIED"
  | "REMOVED"
  | "DELETED"
  | "AUTO_CLOSE";

const ContractsActionsLogsTypeMessage: Record<
  ContractsActionsLogsType,
  string
> = {
  CREATED_BY_OFFER: "Criado no book de benefícios",
  CREATED: "Criado",
  ACCOUNT_ADDED: "Cooperado adicionado",
  SHIPPING_UPDATED: "Frete atualizado",
  SIGNATURES_ADDED: "Assinatura(s) digital(ais) adicionada(s)",
  SIGNATURES_UPDATED: "Assinatura(s) digital(ais) atualizada(s)",
  DOCUMENT_UPLOADED: "Documento enviado",
  DOCUMENT_REMOVED: "Documento removido",
  ACCOUNT_NOTIFIED: "Cooperado notificado",
  ITEM_ADDED: "Item adicionado",
  ITEM_REMOVED: "Item removido",
  STATUS_CHANGED: "Estado alterado",
  STATUS_CHANGED_BY_DIGITAL_SIGNATURE: "Estado alterado por assinatura(s)",
  INSTALLMENTS_CALCULATED: "Valor atualizado",
  MODIFIED: "Alterado",
  REMOVED: "Removido",
  DELETED: "Removido",
  AUTO_CLOSE: "Concluído"
};

const ContractsActionsLogsTypeDotColor: Record<
  ContractsActionsLogsType,
  string
> = {
  CREATED_BY_OFFER: "green",
  CREATED: "green",
  ACCOUNT_ADDED: "blue",
  SHIPPING_UPDATED: "blue",
  SIGNATURES_ADDED: "blue",
  SIGNATURES_UPDATED: "blue",
  DOCUMENT_UPLOADED: "blue",
  DOCUMENT_REMOVED: "blue",
  ACCOUNT_NOTIFIED: "blue",
  ITEM_ADDED: "blue",
  ITEM_REMOVED: "blue",
  STATUS_CHANGED: "green",
  STATUS_CHANGED_BY_DIGITAL_SIGNATURE: "green",
  INSTALLMENTS_CALCULATED: "blue",
  MODIFIED: "blue",
  REMOVED: "red",
  DELETED: "red",
  AUTO_CLOSE: "green"
};

const intl = new Intl.DateTimeFormat("pt-BR", {
  dateStyle: "short",
  timeStyle: "medium"
});

function relativeTime(now: Date, time: Date) {
  const rtf = new Intl.RelativeTimeFormat("pt-BR", { numeric: "auto" });

  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = now.getTime() - time.getTime();

  if (elapsed < msPerMinute) {
    return "agora";
  } else if (elapsed < msPerHour) {
    return rtf.format(-Math.ceil(elapsed / msPerMinute), "minute");
  } else if (elapsed < msPerDay) {
    return rtf.format(-Math.ceil(elapsed / msPerHour), "hour");
  } else if (elapsed < msPerMonth) {
    return rtf.format(-Math.ceil(elapsed / msPerDay), "day");
  } else if (elapsed < msPerYear) {
    return rtf.format(-Math.ceil(elapsed / msPerMonth), "month");
  } else {
    return rtf.format(-Math.ceil(elapsed / msPerYear), "year");
  }
}

function isContractOccurrenceStep(
  occurrence: ContractOccurrence
): occurrence is ContractOccurrenceStep {
  return (
    occurrence.currentStep !== null && occurrence.logType === "STATUS_CHANGED"
  );
}

function isContractOccurrenceItem(
  occurrence: ContractOccurrence
): occurrence is ContractOccurrenceItem {
  return (
    occurrence.contractItem !== null &&
    (occurrence.logType === "ITEM_ADDED" ||
      occurrence.logType === "ITEM_REMOVED")
  );
}

function isContractOccurrenceAccount(
  occurrence: ContractOccurrence
): occurrence is ContractOccurrenceAccount {
  return occurrence.account !== null && occurrence.logType === "ACCOUNT_ADDED";
}

function isContractOccurrenceUpload(
  occurrence: ContractOccurrence
): occurrence is ContractOccurrenceUpload {
  return (
    occurrence.upload !== null &&
    (occurrence.logType === "DOCUMENT_UPLOADED" ||
      occurrence.logType === "DOCUMENT_REMOVED")
  );
}

const CenterWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ContractOccurrencesProps {
  contractId: number;
}

function ContractOccurrences({ contractId }: ContractOccurrencesProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [contractOccurrences, setContractOccurrences] = useState<
    ContractOccurrence[]
  >([]);

  async function getContract(contractId: number) {
    const abortController = new AbortController();
    const { signal } = abortController;

    const service = ContractsService();
    return service.getOccurrences({ id: contractId.toString(), signal });
  }

  useEffect(() => {
    getContract(contractId)
      .then(o => setContractOccurrences(o.data))
      .finally(() => setIsLoading(false));
  }, [contractId]);

  const now = new Date();

  const timelineItems = contractOccurrences.map((occurrence, index) => {
    const createdAt = new Date(occurrence.createdAt);

    const createdAtFormatted = intl.format(createdAt);
    const createdAtRelative = relativeTime(now, createdAt);

    const logTypeMessage = ContractsActionsLogsTypeMessage[occurrence.logType];
    const dotColor = ContractsActionsLogsTypeDotColor[occurrence.logType];

    return (
      <Timeline.Item color={dotColor} key={index}>
        <Paragraph>
          {logTypeMessage} <Text type="secondary">{createdAtRelative}</Text>
        </Paragraph>
        {occurrence.statusChangeReason && (
          <Paragraph>
            <Text strong>Motivo da alteração de Status:</Text>
            <br />
            {occurrence.statusChangeReason}
          </Paragraph>
        )}
        {isContractOccurrenceStep(occurrence) && (
          <Paragraph>
            <Text strong>Atual:</Text> {occurrence.currentStep.friendlyName}
            {occurrence.nextStep && (
              <>
                <br />
                <Text strong>Próximo:</Text> {occurrence.nextStep.friendlyName}
              </>
            )}
          </Paragraph>
        )}

        {isContractOccurrenceItem(occurrence) && (
          <Paragraph>
            {occurrence.contractItem.line && (
              <>
                <Text strong>Linha:</Text>{" "}
                {phoneNumberFormatter(occurrence.contractItem.line.number)}
                <br />
              </>
            )}
            {occurrence.contractItem.offerPrice && (
              <>
                <Text strong>Plano de benefícios:</Text>{" "}
                {occurrence.contractItem.offerPrice.offer.plan.name}
                <br />
                <Text strong>Pacote de Benefícios:</Text>{" "}
                {occurrence.contractItem.offerPrice.offer.combo}
                <br />
              </>
            )}
            {occurrence.contractItem.inventory &&
              occurrence.contractItem.inventory[0] &&
              occurrence.contractItem.inventory.map(iv => (
                <>
                  <Text strong>Benefício combos:</Text> {iv.product?.name}
                  <br />
                  <Text strong>Tipo de série:</Text> {iv.serialType}
                  <br />
                  <Text strong>Número de série:</Text> {iv.serialNumber}
                </>
              ))}
          </Paragraph>
        )}

        {isContractOccurrenceAccount(occurrence) && (
          <Paragraph>
            <Text strong>Nome:</Text> {occurrence.account.fullName}
          </Paragraph>
        )}

        {isContractOccurrenceUpload(occurrence) && (
          <Paragraph>
            <Text strong>Arquivo:</Text> {occurrence.upload.fileName}
          </Paragraph>
        )}

        <Paragraph style={{ fontSize: "0.85em" }} type="secondary">
          {occurrence.user && (
            <>
              {occurrence.user.fullName}
              <br />
            </>
          )}
          <time dateTime={occurrence.createdAt}>{createdAtFormatted}</time>
        </Paragraph>
      </Timeline.Item>
    );
  });

  if (isLoading) {
    return (
      <CenterWrapper>
        <Spin />
      </CenterWrapper>
    );
  }

  if (timelineItems.length === 0) {
    return (
      <CenterWrapper>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Não há histórico para esse contrato"
        />
      </CenterWrapper>
    );
  }

  return <Timeline reverse>{timelineItems}</Timeline>;
}

export default ContractOccurrences;
