import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Icon } from "@fluentui/react/lib/Icon";
import { Stack } from "@fluentui/react/lib/Stack";
import { Button, message, Modal, notification } from "antd";
import { useEffect, useState } from "react";

import { DefaultCardTitle } from "../styles";

import SignatureTable from "./SignatureTable";

import { Card } from "@/components/Shared/Card";
import { ContractsService } from "@/core/libs/api";

const contractsService = ContractsService();

const { confirm } = Modal;

interface ContractSignaturesProps {
  contractId: string;
  eletronicSignature?: any;
}

export function ContractSignatures({
  contractId,
  eletronicSignature
}: ContractSignaturesProps) {
  const [canResendSignatureRequest, setCanResendSignatureRequest] = useState(
    () =>
      eletronicSignature?.document?.signers?.some(
        signer => signer.sign_as === "transferee" && !signer.signature
      )
  );

  useEffect(() => {
    setCanResendSignatureRequest(
      eletronicSignature?.document?.signers?.some(
        signer => signer.sign_as === "transferee" && !signer.signature
      )
    );
  }, [eletronicSignature]);

  function showConfirmResendSignatureRequest() {
    confirm({
      title: "Você tem certeza que deseja reenviar as assinaturas pendentes?",
      icon: <ExclamationCircleOutlined />,
      content:
        "As assinaturas pendentes serão reenviadas para todos que ainda não assinaram.",
      okText: "Reenviar",
      cancelText: "Cancelar",
      centered: true,
      onOk: () => {
        const { signal } = new AbortController();

        return contractsService
          .resendSignatures({ id: contractId, signal })
          .then(({ error, data }) => {
            if (error) {
              return notification.error({
                message: "Não foi possível reenviar assinaturas pendentes",
                description: error.message
              });
            }

            message.success("Assinaturas reenviadas com sucesso!");
          })
          .catch(error => {
            return notification.error({
              message: "Não foi possível reenviar assinaturas pendentes",
              description: error
            });
          });
      }
    });
  }

  return (
    <Stack verticalFill>
      <Stack horizontal horizontalAlign="space-between">
        <DefaultCardTitle level={4}>
          <Icon iconName="OpenEnrollment" style={{ marginRight: 10 }} />
          Assinaturas
        </DefaultCardTitle>
        <Button
          data-testid="resend-documents-button"
          disabled={!canResendSignatureRequest}
          onClick={showConfirmResendSignatureRequest}
        >
          Reenviar
        </Button>
      </Stack>

      <Card>
        <SignatureTable signatures={eletronicSignature} />
      </Card>
    </Stack>
  );
}
