import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Icon, Stack } from "@fluentui/react";
import { Button, Modal } from "antd";
import { id } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";

import { IDocumentEletronicSignature } from "../../interfaces/ITerm.interface";

import SignatureTable from "@/components/Contracts/DisplayContractInfo/ContractSignatures/SignatureTable";
import { DefaultCardTitle } from "@/components/Contracts/DisplayContractInfo/styles";
import { Card } from "@/components/Shared/Card";
import { useApi } from "@/core/libs/api/react-query/useApi";

interface ISignaturesCard {
  documentId: number;
  eletronicSignature?: IDocumentEletronicSignature;
}

export const SignaturesCard = ({
  documentId,
  eletronicSignature: electronicSignature
}: ISignaturesCard) => {
  const { confirm } = Modal;
  const { execute } = useApi();

  const [canResendSignatureRequest, setCanResendSignatureRequest] =
    useState(false);

  const resendSignatures = useCallback(async () => {
    const paramsData = { id: documentId };
    await execute({
      url: `terms/${documentId}/resend-signatures`,
      method: "POST",
      notificationMessage: "Reenviando assinaturas do termo",
      params: Object(paramsData)
    });
  }, [documentId, execute]);

  useEffect(() => {
    setCanResendSignatureRequest(
      electronicSignature?.document?.signers?.some(
        signer => signer.sign_as === "transferee" && !signer.signature
      )
    );
  }, [electronicSignature]);

  const showConfirmResendSignatureRequest = (): void => {
    confirm({
      title: "Você tem certeza que deseja reenviar as assinaturas pendentes?",
      icon: <ExclamationCircleOutlined />,
      content:
        "As assinaturas pendentes serão reenviadas para todos que ainda não assinaram.",
      okText: "Reenviar",
      cancelText: "Cancelar",
      centered: true,
      onOk: () => {
        resendSignatures();
      }
    });
  };

  return (
    <Stack verticalFill>
      <Stack horizontal horizontalAlign="space-between">
        <DefaultCardTitle level={4}>
          <Icon iconName="OpenEnrollment" style={{ marginRight: 10 }} />
          Assinaturas
        </DefaultCardTitle>
        <Button
          disabled={!canResendSignatureRequest}
          onClick={showConfirmResendSignatureRequest}
        >
          Reenviar
        </Button>
      </Stack>
      <Card height="inherit">
        <div style={{ height: "100%" }}>
          <SignatureTable signatures={electronicSignature} />
        </div>
      </Card>
    </Stack>
  );
};
