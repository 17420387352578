import { Descriptions } from "antd";

import { ActivationFeePaymentInfo, PaymentTypes } from "@/modules/User/types";

type PaymentInfoListProps = {
  activationFeePaymentInfo: ActivationFeePaymentInfo[];
};

export function PaymentInfoList({
  activationFeePaymentInfo
}: PaymentInfoListProps): JSX.Element {
  return (
    <Descriptions bordered column={1} layout="vertical">
      {activationFeePaymentInfo.map(paymentInfo => {
        if (paymentInfo.paymentType === PaymentTypes.PIX) {
          return (
            <Descriptions.Item key={PaymentTypes.PIX} label="PIX">
              <Descriptions bordered>
                <Descriptions.Item label="Chave">
                  {paymentInfo.data.key}
                </Descriptions.Item>
              </Descriptions>
            </Descriptions.Item>
          );
        } else if (paymentInfo.paymentType === PaymentTypes.BANK_TRANSFER) {
          return (
            <Descriptions.Item
              key={PaymentTypes.BANK_TRANSFER}
              label="Transferência"
            >
              <Descriptions
                bordered
                column={{ xxl: 3, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              >
                <Descriptions.Item label="Banco">
                  {paymentInfo.data.bankNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Agência">
                  {paymentInfo.data.agencyNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Conta">
                  {paymentInfo.data.accountNumber}
                </Descriptions.Item>
              </Descriptions>
            </Descriptions.Item>
          );
        }

        return null;
      })}
    </Descriptions>
  );
}
