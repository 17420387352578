import { IInvoiceInfos } from "./interfaces";
import { InvoiceMonthly } from "./InvoiceMonthly";
import { InvoicePlans } from "./InvoicePlans";
import { InvoiceSetup } from "./InvoiceSetup";
import {
  InvoiceContainer,
  Container,
  ItemsContainer,
  HeaderContainer
} from "./styles";

interface InvoiceProps {
  invoiceInfos: IInvoiceInfos;
  paymentMethodName: string;
  showInvoicePlans?: boolean;
}

export function Invoice({
  invoiceInfos,
  paymentMethodName,
  showInvoicePlans = false
}: InvoiceProps): JSX.Element {
  const setupInstallments = invoiceInfos?.setup.installments;

  return (
    <InvoiceContainer>
      <Container>
        <HeaderContainer>
          <p className="title">Resumo dos Pagamentos</p>
          <p>Modalidade de pagamento escolhida: {paymentMethodName}</p>
        </HeaderContainer>

        <ItemsContainer>
          {showInvoicePlans && <InvoicePlans plans={invoiceInfos?.plans} />}

          <InvoiceSetup setup={invoiceInfos?.setup} title="Valor a Pagar" />

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-around",
              marginTop: "1rem",
              gap: "6rem"
            }}
          >
            <InvoiceMonthly
              installments={invoiceInfos?.installments}
              title={"Fatura - Valor Mensal"}
            />

            {setupInstallments?.length > 0 && (
              <InvoiceMonthly
                installments={setupInstallments}
                title={`${paymentMethodName} (${setupInstallments?.length}x)`}
                installmentText="ª Parcela"
              />
            )}
          </div>
        </ItemsContainer>
      </Container>
    </InvoiceContainer>
  );
}
