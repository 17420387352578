import styled from "styled-components";

interface Props {
  isResolved: boolean;
}

export const Container = styled.div`
  background-color: white;
  width: 32rem;
  height: auto;

  h6,
  p {
    margin-top: 5px;
    text-align: center;
    color: #395ca3;
    font-weight: 600;
  }

  p {
    font-weight: 400;
  }
`;

export const Content = styled.div<Props>`
  display: flex;
  gap: 1.2rem;
  padding: 0.3rem 1.2rem;
  width: 32rem;
  height: auto;
  background-color: white;

  :hover {
    background-color: #f1f1f9;
  }

  transition: background-color 0.3s ease;
  border-bottom: 1px solid #f1f1f9;
  align-items: center;

  span {
    color: ${props => (props.isResolved ? "#CCCCCC" : "#395ca3")};
    font-weight: 600;
  }

  .notification-message {
    width: 37rem;
    font-size: 0.7rem;
    align-self: center;
  }

  .response-date {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    padding-top: 0.2rem;
    color: ${props => (props.isResolved ? "#CCCCCC" : "#99999d")};
  }

  .response {
    span {
      display: flex;
      padding-top: 0.2rem;
      padding-bottom: 0.2rem;

      :nth-child(1) {
        font-size: 0.8rem;
      }

      :nth-child(2) {
        font-size: 0.6rem;
        color: ${props => (props.isResolved ? "#CCCCCC" : "#000")};
      }
    }
  }

  .secondary-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 12rem;
    height: 5rem;

    span {
      font-size: 0.6rem;
      color: ${props => (props.isResolved ? "#CCCCCC" : "#99999d")};

      .secondary-content-bu {
        color: ${props => (props.isResolved ? "#CCCCCC" : "#395ca3")};
      }
    }

    a {
      border-radius: 15px;
      padding: unset;
      background-color: ${props => (props.isResolved ? "#CCCCCC" : null)};
      border-color: ${props => (props.isResolved ? "#CCCCCC" : null)};

      span {
        color: #fff;
        font-size: 0.6rem;
      }
    }
  }
`;

export const NotificationsHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 2rem;

  span {
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    align-items: center;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.6rem;
      background-color: #395ca3;
      color: #fff;
      margin-left: 0.2rem;
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 50%;
    }
  }

  button {
    font-size: 0.7rem;
    border: none;
    min-width: unset;
    display: contents;
  }
`;

export const NotificationsTabHeader = styled.div`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f9;
  padding-left: 2rem;
  padding-right: 2rem;

  button {
    margin-right: 1.5rem;
    margin-bottom: 0.5rem;
    font-size: 0.7rem;
    font-weight: 700;
    border: none;
    padding: unset;
    min-width: unset;
    color: #395ca3;
    :hover {
      background-color: unset;
    }
  }
  button[aria-selected="false"] {
    color: #cccccc;
    :hover {
      color: #395ca375;
    }
  }

  button[aria-selected="true"] {
    text-decoration: underline;
  }
`;

export const NotificationIconContent = styled.div<Props>`
  .notification-icon {
    width: 2rem;
    filter: ${props => (props.isResolved ? "saturate(0)" : "none")};
    opacity: ${props => (props.isResolved ? 0.3 : "none")};
  }

  .notification-status-icon {
    position: absolute;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background: ${props => (props.isResolved ? "none" : "#b62021")};
    margin: -0.4rem;
  }

  .notification-status-check-icon {
    position: absolute;
    height: 1.3rem;
    width: 1.3rem;
    background: #fff;
    border-radius: 50%;
    margin: -10px;
    left: 5%;
  }
`;
