import {
  PrimaryButton,
  ChoiceGroup,
  IChoiceGroupOption,
  Stack,
  Dropdown,
  IDropdownOption,
  TextField,
  Checkbox,
  TooltipHost,
  Shimmer,
  ShimmerElementType
} from "@fluentui/react";
import { Form, Formik, Field } from "formik";
import { useState, useCallback, useMemo, useEffect } from "react";
import * as Yup from "yup";

import { InvoiceMonthly } from "../components/Invoice/InvoiceMonthly";
import { InvoicePlans } from "../components/Invoice/InvoicePlans";
import { InvoiceSetup } from "../components/Invoice/InvoiceSetup";
import { HeaderContainer } from "../components/Invoice/styles";
import { Contract } from "../contract.interface";

import { CardTitle, Container } from "./styles";
import { getPaymentMethodOptions } from "./utils/getPaymentMethodOptions";

import { PaymentMethodType } from "@/common/types/PaymentMethod.types";
import { Card } from "@/components/Shared/Card";
import {
  useGetPaymentConditions,
  useGetPaymentMethod
} from "@/core/libs/api/react-query";
import { useApi } from "@/core/libs/api/react-query/useApi";
import { PaymentMethods } from "@/modules/Settings/types/OffersPrices.types";
import { ServicesCategoriesTypesEnum } from "@/modules/Settings/types/ServicesCategories.types";

interface PropsPaymentStep {
  monthlyPaymentMethod: string;
  nextStep: (contract: Contract, step?: number) => void;
  contract: Contract;
  paymentMethodId: number;
}

const validationSchema = Yup.object({
  installment: Yup.number().required("Selecione o número de parcelas"),
  paymentMethodId: Yup.string().required("Selecione o método de pagamento"),
  discount: Yup.string().min(0, "O desconto não pode ser negativo"),
  exemptSim: Yup.boolean()
});

const parcelsOptions = ({ limit }: { limit: number }) => {
  const parcels: IDropdownOption[] = [];

  for (let index = 1; index <= limit; index++) {
    parcels.push({
      key: index,
      text: index === 1 ? `${index} parcela` : `${index} parcelas`
    });
  }

  return parcels;
};

export function Step4(props: PropsPaymentStep): JSX.Element {
  const { listOfPaymentMethods } = useGetPaymentMethod();
  const {
    data: invoiceInfos,
    refetch: refetchGetPaymentConditions,
    isFetching: isFetchingPaymentConditions
  } = useGetPaymentConditions({ contractId: props.contract.id });

  const { execute } = useApi();

  const [showHiddenPaymentMethods, setShowHiddenPaymentMethods] =
    useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethods>();
  const [canProceed, setCanProceed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [maxFidelityDuration, setMaxFidelityDuration] = useState(12);

  const handleSelectPaymentMethod = useCallback(
    (option: IChoiceGroupOption) => {
      if (option) {
        const paymentMethod = listOfPaymentMethods.find(
          paymentMethod => paymentMethod.id === parseInt(option.key)
        );

        setSelectedPaymentMethod(paymentMethod);
      }
    },
    [listOfPaymentMethods]
  );

  const assignPaymentMethod = useCallback(
    async ({
      paymentInstallments,
      paymentMethodId,
      exemptSim = false,
      discount = 0
    }: {
      paymentInstallments: number;
      paymentMethodId: number;
      exemptSim?: boolean;
      discount?: number;
    }) => {
      const bodyObj = {
        paymentInstallments,
        paymentMethodId,
        discount,
        exemptSim
      };

      const res = await execute({
        url: `contracts/${props.contract.id}/assign-payment-method`,
        method: "PATCH",
        bodyObj: Object(bodyObj)
      });

      if (res.response.ok) {
        refetchGetPaymentConditions();
      }

      return res;
    },
    [execute, props.contract.id, refetchGetPaymentConditions]
  );

  const handleNextStep = useCallback(() => {
    props.nextStep(props.contract, 5);
  }, [props]);

  const options: IChoiceGroupOption[] = useMemo(() => {
    return getPaymentMethodOptions(
      listOfPaymentMethods,
      showHiddenPaymentMethods
    );
  }, [listOfPaymentMethods, showHiddenPaymentMethods]);

  const parcelOptions = useMemo(() => {
    let limit = selectedPaymentMethod?.maxInstallment;

    if (maxFidelityDuration < limit) {
      limit = maxFidelityDuration;
    }

    return parcelsOptions({
      limit
    });
  }, [maxFidelityDuration, selectedPaymentMethod?.maxInstallment]);

  const submitPaymentMethodForm = useCallback(
    async (data: {
      installment: number;
      discount: string;
      paymentMethodId: string;
      exemptSim: boolean;
    }) => {
      setIsSubmitting(true);
      const assignPayment = await assignPaymentMethod({
        paymentInstallments: data.installment,
        paymentMethodId: parseInt(data.paymentMethodId),
        exemptSim: data.exemptSim,
        discount: parseCurrencyMask(data.discount)
      });

      setIsSubmitting(false);

      if (assignPayment.response.ok) {
        setCanProceed(true);
      } else {
        setCanProceed(false);
      }
    },
    [assignPaymentMethod]
  );

  useEffect(() => {
    if (!listOfPaymentMethods?.length) return;

    if (props.contract.paymentMethodId) {
      setCanProceed(true);

      const paymentMethod = listOfPaymentMethods.find(
        paymentMethod => paymentMethod.id === props.contract.paymentMethodId
      );

      if (paymentMethod.hidden) {
        setShowHiddenPaymentMethods(true);
      }

      setSelectedPaymentMethod(paymentMethod);

      const comboInstallments =
        props.contract.processedInstallment?.comboInstallments[0];

      const paymentInstallments = comboInstallments?.qty || 1;

      assignPaymentMethod({
        paymentInstallments,
        paymentMethodId: paymentMethod.id,
        exemptSim: props.contract.activationInstallment.exemptSim,
        discount: props.contract.activationInstallment.discount
      });
    } else {
      const paymentMethod = listOfPaymentMethods.find(
        item => item.type === PaymentMethodType.PIX
      );

      setSelectedPaymentMethod(paymentMethod);
      assignPaymentMethod({
        paymentInstallments: 1,
        paymentMethodId: paymentMethod.id,
        exemptSim: props.contract.activationInstallment.exemptSim
      });
    }
  }, [
    assignPaymentMethod,
    listOfPaymentMethods,
    props.contract.activationInstallment.discount,
    props.contract.activationInstallment.exemptSim,
    props.contract.paymentMethodId,
    props.contract.processedInstallment?.comboInstallments
  ]);

  useEffect(() => {
    const contractItems = props.contract.items;

    if (contractItems.length === 0) return;

    for (const item of contractItems) {
      const fidelityDuration = Number(
        item.offerPrice?.offer?.fidelityDuration || 0
      );
      const fidelityDurationType = item.offerPrice?.offer?.fidelityDurationType;

      if (
        fidelityDurationType === "MONTH" &&
        fidelityDuration > maxFidelityDuration
      ) {
        setMaxFidelityDuration(fidelityDuration);
      }
    }
  }, [maxFidelityDuration, props.contract.items]);

  if (
    props.contract.serviceCategory?.type === ServicesCategoriesTypesEnum.TAG
  ) {
    return (
      <Stack.Item
        style={{
          textAlign: "center",
          maxWidth: "600px",
          margin: "0 auto"
        }}
      >
        <Card
          style={{
            padding: "30px",
            marginTop: "20px",
            borderRadius: "8px",
            gap: "20px"
          }}
        >
          <HeaderContainer>
            <h1>Método de pagamento da Tag</h1>
            <h2>
              Para os serviços de tag o método de pagamento é selecionado
              automaticamente. Aperte o botão de prosseguir para ir para a
              próxima etapa.
            </h2>
          </HeaderContainer>
          <PrimaryButton id="submit-button" onClick={handleNextStep}>
            Prosseguir
          </PrimaryButton>
        </Card>
      </Stack.Item>
    );
  }

  return (
    <Container>
      <Card
        width="60%"
        padding="2rem"
        borderRadius="0.75rem"
        margin="0 0 2rem 0"
      >
        <CardTitle>Resumo dos Benefícios Escolhidos</CardTitle>
        <InvoicePlans
          plans={invoiceInfos?.plans}
          isLoading={isFetchingPaymentConditions}
        />
      </Card>

      <Card width="60%" padding="2rem" borderRadius="0.75rem">
        <CardTitle>Resumo do Valor a Pagar</CardTitle>

        <Stack horizontal style={{ display: "flex" }}>
          <Stack.Item grow={1} styles={{ root: { flexBasis: "50%" } }}>
            <InvoiceSetup
              setup={invoiceInfos?.setup}
              isLoading={isFetchingPaymentConditions}
            />
          </Stack.Item>

          <Stack.Item
            grow={3}
            styles={{
              root: {
                flexBasis: "50%",
                paddingLeft: "2rem"
              }
            }}
          >
            {selectedPaymentMethod?.id ? (
              <Formik
                initialValues={{
                  paymentMethodId:
                    props.contract.paymentMethodId?.toString() ||
                    selectedPaymentMethod?.id.toString(),
                  installment:
                    props.contract.processedInstallment?.comboInstallments[0]
                      ?.qty || 1,
                  discount: String(
                    props.contract.activationInstallment.discount || 0
                  ),
                  exemptSim:
                    props.contract.activationInstallment.exemptSim || false
                }}
                validationSchema={validationSchema}
                onSubmit={submitPaymentMethodForm}
              >
                {({
                  setFieldValue,
                  setFieldError,
                  submitForm,
                  values,
                  errors
                }) => {
                  return (
                    <Form>
                      <div>
                        <p
                          style={{ marginBottom: "1rem" }}
                          className="ms-fontSize-18"
                        >
                          Escolha sua forma de pagamento
                        </p>

                        <ChoiceGroup
                          id="payment-method-select"
                          defaultSelectedKey={values.paymentMethodId || 4}
                          options={options}
                          onChange={(_, newValue) => {
                            handleSelectPaymentMethod(newValue);
                            setFieldValue("paymentMethodId", newValue.key);
                            setFieldValue("installment", 1);
                            submitForm();
                          }}
                          styles={{
                            root: { width: "100%", margin: "0 0 10px 0" }
                          }}
                        />

                        <TooltipHost
                          content="Opção sujeita a deliberação comercial. Consulte o time de CRC para que haja conformidade na utilização deste campo."
                          id="tooltip-personalized-payment-method"
                          delay={0}
                          tooltipProps={{
                            styles: {
                              subText: { fontSize: 14 }
                            }
                          }}
                        >
                          <PrimaryButton
                            text="Personalizar"
                            onClick={() => {
                              setShowHiddenPaymentMethods(prev => !prev);
                            }}
                            style={{
                              background: "none",
                              border: "none",
                              color: "#395ca3",
                              padding: "0",
                              margin: "0",
                              textAlign: "left"
                            }}
                          />
                        </TooltipHost>
                      </div>
                      {selectedPaymentMethod && showHiddenPaymentMethods && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            maxWidth: "10rem",
                            marginBottom: "1rem",
                            rowGap: "1rem"
                          }}
                        >
                          <Field
                            name="discount"
                            id="discount-input"
                            type="text"
                            prefix="R$ "
                            label="Desconto na adesão"
                            errorMessage={errors["discount"] || null}
                            as={TextField}
                            onChange={(
                              _: React.FormEvent<HTMLInputElement>,
                              newValue: string
                            ) => {
                              const formattedValue =
                                formatCurrencyMask(newValue);
                              const value = parseCurrencyMask(formattedValue);

                              if (
                                value > Number(invoiceInfos?.setup.monthlyFee)
                              ) {
                                setFieldError(
                                  "discount",
                                  "O desconto não pode ser maior que o total da adesão"
                                );

                                return;
                              }

                              setFieldValue("discount", formattedValue);
                            }}
                            onBlur={() => {
                              submitForm();
                            }}
                          />
                          <Field
                            as={Checkbox}
                            name="exemptSim"
                            id="exempt-sim"
                            type="checkbox"
                            label="Isentar Chips"
                            onChange={(
                              _: React.FormEvent<HTMLInputElement>,
                              newValue: boolean
                            ) => {
                              setFieldValue("exemptSim", newValue);
                              submitForm();
                            }}
                          />
                        </div>
                      )}
                      <Field
                        name="installment"
                        label="Quantidade de parcelas"
                        required
                        as={Dropdown}
                        options={parcelOptions}
                        errorMessage={errors["installment"] || null}
                        selectedKey={values.installment}
                        onChange={(
                          _: React.FormEvent<HTMLDivElement>,
                          option: IDropdownOption<string>
                        ) => {
                          setFieldValue("installment", option?.key);
                          submitForm();
                        }}
                      />
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <Shimmer
                    key={index}
                    style={{ marginBottom: "0.5rem" }}
                    shimmerElements={[
                      {
                        type: ShimmerElementType.line,
                        height: 25
                      }
                    ]}
                  />
                ))}
              </>
            )}
          </Stack.Item>
        </Stack>
      </Card>

      <Card
        width="60%"
        padding="2rem"
        borderRadius="0.75rem"
        margin="2rem 0 2rem 0"
      >
        <CardTitle>Resumo dos Pagamentos</CardTitle>

        <div
          style={{
            display: "flex",
            width: "100%",
            paddingTop: "2rem",
            justifyContent: "space-around",
            gap: "2rem"
          }}
        >
          <InvoiceMonthly
            installments={invoiceInfos?.installments}
            title={"Fatura - Valor Mensal"}
            accordionStartsOpen={true}
            isLoading={isFetchingPaymentConditions}
          />

          {invoiceInfos?.setup.installments.length > 0 && (
            <InvoiceMonthly
              installments={invoiceInfos?.setup.installments}
              title={selectedPaymentMethod?.name}
              installmentText="ª Parcela"
              accordionStartsOpen={true}
              isLoading={isFetchingPaymentConditions}
            />
          )}
        </div>
      </Card>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <PrimaryButton
          style={{ borderRadius: "0.4rem" }}
          text="Prosseguir para confirmação"
          disabled={isSubmitting || !canProceed}
          onClick={() => {
            handleNextStep();
          }}
        />
      </div>
    </Container>
  );
}

function formatCurrencyMask(value: string): string {
  if (value) {
    const numericValue = parseFloat(value.replace(/\D/g, ""));

    if (isNaN(numericValue)) return undefined;

    const v = (numericValue / 100).toFixed(2).split(".");

    const m = v[0]
      .split("")
      .reverse()
      .join("")
      .match(/.{1,3}/g);

    for (let i = 0; i < m.length; i++)
      m[i] = m[i].split("").reverse().join("") + ".";

    const r = m.reverse().join("");

    return r.substring(0, r.lastIndexOf(".")) + "," + v[1];
  }
}

function parseCurrencyMask(formattedValue: string): number {
  if (formattedValue) {
    const numericValue = formattedValue
      .replace(/\.(?=\d{3},)/g, "")
      .replace(",", ".");
    return parseFloat(numericValue);
  }

  return null;
}
