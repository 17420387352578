import {
  getTheme,
  Icon,
  IIconStyles,
  PrimaryButton,
  Stack,
  Text
} from "@fluentui/react";

import {
  CarrierLogo,
  CashValue,
  FilterButton,
  FilterContainer,
  FilterItem,
  FiltersOptions,
  InstallmentValue
} from "./ProductFilter.styles";

import { ProductOfferItem } from "@/modules/Offers/types/ProductsOffers.types";

type ProductFilterProps = {
  productOffer: ProductOfferItem;
  currentCashFilter: string;
  currentCarrierFilter: string;
  handleCashFilterClick: (filter: string) => void;
  handleCarrierFilterClick: (filter: string) => void;
  handleAllFiltersClick: () => void;
};

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}

const iconStyles: IIconStyles = {
  root: {
    fontSize: "20px",
    height: "20px",
    width: "20px",
    marginTop: "4px"
  }
};

const theme = getTheme();

export function ProductFilter({
  productOffer,
  currentCashFilter,
  currentCarrierFilter,
  handleCashFilterClick,
  handleCarrierFilterClick,
  handleAllFiltersClick
}: ProductFilterProps): JSX.Element {
  return (
    <FilterContainer>
      {productOffer?.allCashValue?.length > 0 && (
        <Stack
          tokens={{ padding: 30 }}
          className="ms-depth-8"
          style={{ width: "100%", background: theme.palette.white }}
          horizontalAlign="center"
        >
          <Text variant={"xLarge"}>Selecionar por valor</Text>

          <FiltersOptions>
            {productOffer.allCashValue.map(value => (
              <FilterButton
                key={value}
                isActive={currentCashFilter === value}
                onClick={() => handleCashFilterClick(value)}
              >
                <CashValue
                  className="ms-fontSize-24"
                  style={{
                    textDecoration:
                      Number(value) > 0 ? "initial" : "line-through"
                  }}
                >
                  {toLocaleString(value)}
                </CashValue>

                <InstallmentValue className="ms-fontSize-20 ms-fontWeight-semibold">
                  {Number(value) > 0
                    ? `1 + 23X ${toLocaleString(Number(value) / 24)}`
                    : "GRÁTIS"}
                </InstallmentValue>
              </FilterButton>
            ))}
          </FiltersOptions>
        </Stack>
      )}

      {productOffer?.allCarriers?.length > 0 && (
        <Stack
          tokens={{ padding: 30 }}
          className="ms-depth-8"
          style={{
            width: "100%",
            background: theme.palette.white,
            marginTop: 20
          }}
          horizontalAlign="center"
        >
          <Text variant={"xLarge"}>Selecionar por operadora</Text>

          <FiltersOptions>
            {productOffer.allCarriers.map(carrier => {
              const currentCarrier = productOffer.saleConditions.find(
                sale => sale.carrier === carrier
              );

              return (
                <FilterButton
                  key={carrier}
                  isActive={currentCarrierFilter === carrier}
                  onClick={() => handleCarrierFilterClick(carrier)}
                  style={{ height: 100, width: 150 }}
                >
                  <CarrierLogo
                    src={currentCarrier?.offerPrice?.offer?.plan?.carrier?.logo}
                    alt={currentCarrier.carrier}
                  />
                </FilterButton>
              );
            })}
          </FiltersOptions>
        </Stack>
      )}

      <FilterItem
        style={{
          borderTop: `2px solid ${theme.palette.neutralLight}`,
          paddingTop: 20,
          position: "absolute",
          bottom: 10,
          width: "90%"
        }}
      >
        <PrimaryButton style={{ padding: 25 }} onClick={handleAllFiltersClick}>
          <span className="ms-fontSize-18">
            Ver todos os planos de benefícios
          </span>
          <Icon iconName="ChevronRightMed" styles={iconStyles} />
        </PrimaryButton>
      </FilterItem>
    </FilterContainer>
  );
}
