import { useId } from "@fluentui/react-hooks";
import { hoursToMilliseconds } from "date-fns";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from "react";

import {
  ApiAlertsResponseProps,
  IApiAlertsData
} from "@/common/types/ApiAlertsTypes";
import { ResponseApiAlertsContent } from "@/components/Shared/Layouts/components/ResponseApiAlerts/ResponseApiAlertsContent";

const ApiAlertsContext = createContext<IApiAlertsData>({} as IApiAlertsData);

const cachedResponsesKey = "cachedResponses";

export const ApiAlertsProvider = ({ children }: { children: ReactNode }) => {
  const [cachedApiAlertsResponse, setCachedApiAlertsResponse] = useState<
    ApiAlertsResponseProps[]
  >([]);
  const [isTeachingBobbleVisible, setIsTeachingBobbleVisible] = useState(false);

  const handleBubbleVisibility = () => {
    setIsTeachingBobbleVisible(prevState => !prevState);
  };

  const openApiAlertsResponsesButtonId = useId("open-api-alerts");
  const fortyEightHoursMilliseconds = hoursToMilliseconds(48);
  const fortyEightHoursAgo = Date.now() - fortyEightHoursMilliseconds;

  const removeOlderThan48Hours = useCallback(
    (items: ApiAlertsResponseProps[]): ApiAlertsResponseProps[] => {
      const now = Date.now();
      const objectsToMaintain: ApiAlertsResponseProps[] = [];

      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (item) {
          if (now - item.createdAt < fortyEightHoursAgo) {
            objectsToMaintain.push(item);
          }
        }
      }

      return objectsToMaintain;
    },
    [fortyEightHoursAgo]
  );

  const createApiAlertsResponse = useCallback(
    (data: ApiAlertsResponseProps) => {
      setCachedApiAlertsResponse(prevState => {
        const newState = [...prevState, data];

        const stringifyResponse = JSON.stringify(newState);
        localStorage.setItem(cachedResponsesKey, stringifyResponse);

        return newState;
      });
    },
    []
  );

  const getApiResponseFromLocalStorage = useCallback(() => {
    const responses = localStorage.getItem(cachedResponsesKey);

    if (responses?.length) {
      const filteredResponses = removeOlderThan48Hours(JSON.parse(responses));
      return filteredResponses;
    } else {
      return [];
    }
  }, [removeOlderThan48Hours]);

  const getCachedApiAlertsResponse = useCallback(() => {
    if (!cachedApiAlertsResponse.length) {
      const apiResponses = getApiResponseFromLocalStorage();
      setCachedApiAlertsResponse(apiResponses);

      return apiResponses;
    } else {
      return cachedApiAlertsResponse;
    }
  }, [cachedApiAlertsResponse, getApiResponseFromLocalStorage]);

  useEffect(() => {
    getCachedApiAlertsResponse();
  }, []);

  return (
    <ApiAlertsContext.Provider
      value={{
        createApiAlertsResponse,
        handleBubbleVisibility,
        isTeachingBobbleVisible,
        openApiAlertsResponsesButtonId
      }}
    >
      {isTeachingBobbleVisible && (
        <ResponseApiAlertsContent
          handleAlertBubbleVisibility={handleBubbleVisibility}
          openApiAlertsResponsesButtonId={openApiAlertsResponsesButtonId}
          responses={cachedApiAlertsResponse}
        />
      )}
      {children}
    </ApiAlertsContext.Provider>
  );
};

export const UseApiAlerts = () => {
  const context = useContext(ApiAlertsContext);
  return context;
};
