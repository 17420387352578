import { IconButton, mergeStyleSets, Modal, Stack } from "@fluentui/react";
import { useState } from "react";

import { CardPlan } from "./CardPlan";
import { PlanOfferContract } from "./PlanOfferContract";

import { FilterParamsType } from "@/common/types/ListFromApi.types";
import { Loader } from "@/components/Shared/Loader";
import {
  ListOfPlansOffers,
  Plan,
  PlanOfferItem
} from "@/modules/Offers/types/PlansOffers.types";

type PlansOffersProps = {
  plans?: ListOfPlansOffers;
  requestListOfPlansOffers?: (filters?: FilterParamsType) => void;
  removeListOfPlansOffers?: () => void;
  isLoadingList?: boolean;
};

export function PlansOffers({
  plans,
  isLoadingList
}: PlansOffersProps): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan>(undefined);
  const [selectedCarrier, setSelectedCarrier] =
    useState<PlanOfferItem>(undefined);

  const toggleOpenModal = () => {
    setIsModalOpen(prevState => {
      return !prevState;
    });
  };

  return (
    <>
      <h1
        className="ms-fontWeight-semibold ms-fontSize-24"
        style={{ paddingTop: 10 }}
      >
        Pacote de Benefícios Móvel e Fixa
      </h1>
      <Stack
        horizontalAlign="center"
        styles={{
          root: { marginTop: 20, position: "relative", minHeight: "200px" }
        }}
        tokens={{ padding: 20 }}
      >
        {isLoadingList ? (
          <Loader height="300px" />
        ) : (
          <Stack
            styles={{ root: { maxWidth: 2000, width: "100%" } }}
            horizontalAlign="center"
            tokens={{ childrenGap: 25 }}
            horizontal
            wrap
          >
            {plans?.map(plan => (
              <CardPlan
                key={plan.id}
                plan={plan}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                selectedCarrier={selectedCarrier}
                setSelectedCarrier={setSelectedCarrier}
                openModal={toggleOpenModal}
              />
            ))}
          </Stack>
        )}
      </Stack>

      <Modal
        titleAriaId="Plan Contract Offer"
        isOpen={isModalOpen}
        onDismiss={toggleOpenModal}
        onDismissed={() => {
          setSelectedPlan(undefined);
          setSelectedCarrier(undefined);
        }}
        isBlocking={false}
        containerClassName={contentStyles.container}
        scrollableContentClassName={scrollableStyles.container}
      >
        <IconButton
          styles={{
            root: {
              zIndex: 1,
              position: "absolute",
              top: 10,
              right: 10
            }
          }}
          iconProps={{ iconName: "Cancel" }}
          ariaLabel="Close popup modal"
          onClick={toggleOpenModal}
        />
        <PlanOfferContract
          selectedPlan={selectedPlan}
          selectedCarrier={selectedCarrier}
        />
      </Modal>
    </>
  );
}

const scrollableStyles = mergeStyleSets({
  container: {
    display: "flex"
  }
});

const contentStyles = mergeStyleSets({
  container: {
    display: "flex",
    flexFlow: "column nowrap",
    alignItems: "stretch"
  }
});
