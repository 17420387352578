import { AccountType, AccountTypeTranslator } from "../types/Accounts.types";

import { FiltersProps } from "@/core/libs/list-data/lib/types";
import { cpfCnpjFormatter } from "@/utils/FormatCPForCNPJ";

const removeMask = (value: string) => {
  return value?.replace(/\D/g, "");
};

export const accountFilters: FiltersProps[] = [
  {
    label: "Nome",
    placeholder: "Nome",
    filterQuery: "fullName",
    type: "text",
    maxWidth: "200px"
  },
  {
    label: "Tipo",
    placeholder: "Tipo",
    type: "select",
    filterQuery: "accountType",
    options: [
      { key: AccountType.LEGAL, text: AccountTypeTranslator.LEGAL },
      { key: AccountType.PHYSICAL, text: AccountTypeTranslator.PHYSICAL }
    ],
    minWidth: "150px",
    maxWidth: "150px"
  },
  {
    label: "CPF/CNPJ",
    placeholder: "CPF/CNPJ",
    filterQuery: "documentNumber",
    type: "text",
    maxWidth: "200px",
    maskFormatter: cpfCnpjFormatter,
    valueFormatter: removeMask
  },
  {
    label: "Contato",
    placeholder: "Contato",
    filterQuery: "contact",
    type: "text",
    maxWidth: "150px"
  },
  {
    label: "Nome Unidade de Negocio",
    placeholder: "Unidade de Negocio",
    filterQuery: "businessUnitName",
    type: "text",
    maxWidth: "200px"
  }
];
