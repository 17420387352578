import { MutableRefObject } from "react";

import { FetchResponse } from "@/core/libs/api/utils/fetchData";
import { IMessageBar } from "@/core/libs/message-bar";
import { User } from "@/modules/Settings/types/Users.types";

export type AccountsProps = {
  canCreate: boolean;
  canEdit: boolean;
};

export interface FieldsProps {
  id: number;
  createdAt: string;
  fullName: string;
  maritalStatus: IMaritalStatus;
  accountType: AccountType;
  occupation: string | null;
  birthDate: string | null;
  businessUnit: BusinessUnit;
  documents: Document[];
  contacts: Contact[];
  addresses: Address[];
  checkingAccounts: CheckingAccount[];
  legalRepresentatives: LegalRepresentatives[];
  genderStatus: GenderStatus;
}

export interface EditAccount {
  updatedAt: Date;
  accountType: AccountType;
  addresses: Address[];
  birthDate: string | null;
  businessUnit: BusinessUnit;
  checkingAccounts: CheckingAccount[];
  contacts: Contact[];
  documents: Document[];
  fullName: string;
  id: number;
  legalRepresentatives: LegalRepresentatives[];
  maritalStatus: string;
  occupation: string | null;
  genderStatus: GenderStatus;
  user: User;
}
export interface CreateProps {
  create: (values, { setSubmitting }, closeDrawer) => Promise<FetchResponse>;
  dismissMessage?: () => void;
  closeDrawer: () => void;
  message: IMessageBar;
  businessActionId?: number;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  document?: string;
  maritalStatusOptions: {
    key: number;
    text: string;
  }[];
}

export enum MaritalStatusTypes {
  DIVORCED = "DIVORCED",
  MARRIED = "MARRIED",
  SINGLE = "SINGLE",
  COMMON_LAW_MARRIAGE = "COMMON_LAW_MARRIAGE",
  RATHER_NOT_SAY = "RATHER_NOT_SAY",
  OTHER = "OTHER",
  WIDOWED = "WIDOWED"
}

export enum MaritalStatusTranslate {
  DIVORCED = "Divorciado(a)",
  MARRIED = "Casado(a)",
  SINGLE = "Solteiro(a)",
  COMMON_LAW_MARRIAGE = "União Estável",
  RATHER_NOT_SAY = "Prefiro não informar",
  OTHER = "Outros",
  WIDOWED = "Viúvo(a)"
}
export interface EditProps {
  edit: (values, { setSubmitting }, closeDrawer) => Promise<FetchResponse>;
  closeDrawer: () => void;
  dismissMessage: () => void;
  drawerContainerRef: MutableRefObject<HTMLDivElement>;
  message: IMessageBar;
  selectedItem: string;
  maritalStatusOptions: {
    key: number;
    text: string;
  }[];
  addWhatsApp?: boolean;
  checkingAccountsRequired?: boolean;
  isTerm?: boolean;
}

export interface CreateAccountSubmitFields {
  [x: string]: any;
  create: (values, { setSubmitting }) => void;
  message: IMessageBar;
  dismissMessage: () => void;
  isSubmitted: boolean;
  drawerContainerRef?: MutableRefObject<HTMLDivElement>;
  document?: string;
  account?: string;
}
export interface EditAccountSubmitFields {
  create: (values, { setSubmitting }) => void;
  message: IMessageBar;
  dismissMessage: () => void;
  isSubmitted: boolean;
  drawerContainerRef?: MutableRefObject<HTMLDivElement>;
  document?: string;
  account?: string;
}

export interface CreateAccountForm {
  create: (values, { setSubmitting }) => void;
  message: any;
  dismissMessage: () => void;
  handleCloseButton?: () => void;
  isSubmitted: boolean;
  isInsideDrawer?: boolean;
  drawerContainerRef?: MutableRefObject<HTMLDivElement>;
  document?: string;
  paymentMethod?: string;
  bankNumber?: string;
  agencyNumber?: string;
  isAutomaticDebit?: boolean;
}

export enum AccountType {
  PHYSICAL = "PHYSICAL",
  LEGAL = "LEGAL"
}

export enum AccountTypeTranslator {
  PHYSICAL = "PESSOA FÍSICA",
  LEGAL = "PESSOA JURÍDICA"
}

export enum MaritalStatus {
  DIVORCED = "DIVORCED",
  MARRIED = "MARRIED",
  SINGLE = "SINGLE",
  WIDOWED = "WIDOWED",
  RATHER_NOT_SAY = "RATHER_NOT_SAY",
  OTHER = "OTHER"
}

export enum GenderStatus {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
  RATHER_NOT_SAY = "RATHER_NOT_SAY"
}

export enum AccountDocumentType {
  CNPJ = "CNPJ",
  CPF = "CPF",
  RG = "RG",
  PASSPORT = "PASSPORT",
  TITULO_ELEITOR = "TITULO_ELEITOR",
  IE = "IE",
  PIS_PASEP = "PIS_PASEP",
  CNH = "CNH",
  CERTIDAO_NASCIMENTO = "CERTIDAO_NASCIMENTO",
  CERTIFICADO_RESERVISTA = "CERTIFICADO_RESERVISTA",
  INSCRICAO_MUNICIPAL = "INSCRICAO_MUNICIPAL",
  OUTROS = "OUTROS"
}

export enum AccountDocumentTranslated {
  CNPJ = "CNPJ",
  CPF = "CPF",
  RG = "RG",
  PASSPORT = "Passaporte",
  TITULO_ELEITOR = "Título de eleitor",
  IE = "Inscrição estadual",
  PIS_PASEP = "PIS/PASEP",
  CNH = "CNH",
  CERTIDAO_NASCIMENTO = "Certidão de nascimento",
  CERTIFICADO_RESERVISTA = "Certificado de reservista",
  OUTROS = "Outros"
}

export enum AccountContactType {
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL = "EMAIL",
  WHATSAPP = "WHATSAPP"
}

export enum AccountContactTypeTranslated {
  PHONE_NUMBER = "Telefone",
  EMAIL = "E-mail",
  WHATSAPP = "WhatsApp"
}

export enum CheckingAccountType {
  DEFAULT = "DEFAULT",
  CHARGEBACK = "CHARGEBACK"
}

export enum TranslatedAccountType {
  PHYSICAL = "Pessoa Física",
  LEGAL = "Pessoa Jurídica"
}

export enum PaymentMethod {
  BOLETO = "BOLETO",
  DEBITO_AUTOMATICO = "DEBITO_AUTOMATICO",
  OUTROS = "OUTROS"
}

export interface BusinessUnit {
  id: number;
}

export interface Document {
  id: number;
  documentNumber: string;
  documentType: string;
}

export interface Contact {
  id: number;
  contact: string;
  contactType: string;
}

export interface Address {
  id: number;
  zipCode: string;
  city: string;
  state: string;
  street: string;
  neighborhood: string;
  number: string;
  note: any;
}

export interface CheckingAccount {
  id: number;
  agencyNumber: number;
  accountNumber: number;
  bankNumber: number;
}
export interface LegalRepresentatives {
  id: number;
  fullName: string;
  email: string;
  document: string;
  birthDate: string | null;
  role: string;
  whatsapp?: string;
}

export interface IMaritalStatus {
  id?: number;
  status?: boolean;
  name: string;
  isBankId: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
