import styled from "styled-components";

export const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AccordionTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 6px;

  border-radius: 0px 0px 0px 4px;
  background-color: #fff;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);

  cursor: pointer;

  color: rgb(51, 83, 147);
  font-size: 1rem;

  & > p {
    font-size: 0.8rem;
  }
`;

export const AccordionContent = styled.div`
  padding: 1px 6px;
  margin-left: 0.65rem;
  background-color: #f2f2f2;
  box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132),
    0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
  border-radius: 0px 0px 0px 4px;
`;

export const AccordionContentWrapper = styled.ul`
  margin: 0;
  padding: 0.2rem 0px;
  & > li > span {
    font-weight: 600;
    color: rgb(51, 83, 147);
    font-size: 1rem;
  }
  & > li > strong {
    color: #d83b01;
    font-size: 1rem;
  }
`;

export const TotalValue = styled.span`
  font-size: 0.8rem;
  margin-right: 0.5rem;
  strong {
    margin-right: 1rem;
  }
`;
