import { Space } from "antd";
import MaskedInput from "antd-mask-input";

import { FilterComponentProps } from "../types";

import { isObjectEmpty } from "@/utils/IsObjectEmpty";

export function MaskedTextFilter({
  filterConfigs,
  selectedTerms,
  visibleTerms,
  setVisibleTerms,
  setSelectedTerms
}: FilterComponentProps): JSX.Element {
  return (
    <Space direction="vertical" size="small">
      <p style={{ marginBottom: "0px" }}>{filterConfigs.label}</p>

      <MaskedInput
        disabled={filterConfigs.disabled ? true : false}
        mask={filterConfigs.mask}
        placeholder={filterConfigs.placeholder}
        type={filterConfigs.inputType ?? filterConfigs.type}
        onChange={event => {
          setVisibleTerms(prevState => {
            return {
              ...prevState,
              [filterConfigs.filterQuery as string]: event.target.value
            };
          });

          const conditionalSearch = filterConfigs.customConditional
            ? filterConfigs.customConditional(event.target.value)
            : filterConfigs.valueFormatter(event.target.value) !== "";

          if (conditionalSearch) {
            setSelectedTerms(prevState => {
              return {
                ...prevState,
                [filterConfigs.filterQuery as string]:
                  filterConfigs.valueFormatter(event.target.value)
              };
            });
          } else if (
            !isObjectEmpty(selectedTerms) &&
            selectedTerms[filterConfigs.filterQuery as string]
          ) {
            setSelectedTerms(prevState => {
              delete prevState[filterConfigs.filterQuery as string];

              return {
                ...prevState
              };
            });
          }
        }}
        value={visibleTerms[filterConfigs.filterQuery as string] ?? ""}
        maxLength={filterConfigs.maxLength}
        style={{
          minWidth: filterConfigs?.minWidth
        }}
      />
    </Space>
  );
}
