import { IColumn, SelectionMode } from "@fluentui/react";
import { useState } from "react";

import { useGetContracts } from "@/core/libs/api/react-query/hook/use-get-contracts";
import { ListData } from "@/core/libs/list-data";
import { ContractBadgeColors } from "@/modules/Contracts/enums";
import * as S from "@/modules/Contracts/styles";

type ProductContractListProps = {
  setSelectedContract: (arg) => void;
};

export function ProductContractList({
  setSelectedContract
}: ProductContractListProps): JSX.Element {
  const [useGetContractsParams, setUseGetContractsParams] = useState<object>(
    {}
  );
  const { contractsList, isLoadingContractsList } = useGetContracts(
    useGetContractsParams
  );

  function paginate(params) {
    setUseGetContractsParams({
      ...params
    });
  }

  return (
    <ListData
      isLoadingList={isLoadingContractsList}
      items={contractsList?.contracts || []}
      selectionMode={SelectionMode.single}
      columns={buildColumns()}
      menuItems={undefined}
      setSelectedItem={setSelectedContract}
      isCheckSelection
      selectionPreservedOnEmptyClick
      _paginate={paginate}
      showTagList={false}
      hasFilters
      // Very little space to show this.
      isItemsPerPageFilterDisabled={true}
      isDefaultFilterVisible
      isCloseFilterButtonDisabled
      filters={[
        {
          label: "Meus contratos",
          filterQuery: "me",
          type: "checkbox",
          defaultValue: "true"
        }
      ]}
    />
  );
}

function buildColumns() {
  const columns: IColumn[] = [
    {
      key: "id",
      name: "Código",
      minWidth: 20,
      maxWidth: 50,
      fieldName: "id",
      isRowHeader: true,
      data: "string"
    },
    {
      key: "account",
      name: "Cooperado",
      minWidth: 100,
      fieldName: "account",
      isRowHeader: true,
      data: "string",
      onRender: item =>
        item.account?.fullName ?? (
          <S.Badge
            bgColor="transparent"
            fontWeight="600"
            fontColor={ContractBadgeColors.DISABLED}
            border={`1px solid ${ContractBadgeColors.DISABLED}`}
          >
            Nenhum cooperado selecionado
          </S.Badge>
        ),
      isPadded: true
    },
    {
      key: "createdAt",
      name: "Data",
      fieldName: "createdAt",
      isRowHeader: true,
      minWidth: 100,
      data: "string",
      onRender: ({ createdAt }) => {
        if (createdAt) {
          return (
            <div>
              {new Intl.DateTimeFormat("pt-BR").format(new Date(createdAt))}
            </div>
          );
        }
      }
    }
  ];

  return columns;
}
