// Se o inferno são os outros, esses arquivos são os outros. 🔥
// Tenha paciência e calma.

import { IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { Stack } from "@fluentui/react/lib/Stack";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { Contract } from "../contract.interface";

import { FormStepTwo } from "./Form";
import { SearchClient } from "./SearchClient";

import { Card } from "@/components/Shared/Card";
import { ShowMessageCard } from "@/components/Shared/MessageBar";
import { IMessageBar, messageBarTypes } from "@/core/libs/message-bar";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import { AccountContactType } from "@/modules/Accounts/types";
import { StyledStep } from "@/modules/Contracts/styles";

interface PropsStep2 {
  user?: any;
  contract?: Contract;
  nextStep?: (contract, step) => void;
  setContractMessage: React.Dispatch<any | undefined>;
}

export function Step2({ contract, setContractMessage, ...props }: PropsStep2) {
  const [message, setMessage] = useState<IMessageBar>(undefined);
  const [account, setAccount] = useState(undefined);
  const [initialValues, setInitialValues] = useState(undefined);

  const [selectedCheckingAccount, setSelectedCheckingAccount] =
    useState(undefined);
  const [selectedEmail, setSelectedEmail] = useState(undefined);
  const [selectedAddress, setSelectedAddress] = useState(undefined);

  const [isEditingAccount, setIsEditingAccount] = useState(false);
  const [isAccountIncomplete, setIsAccountIncomplete] = useState(false);
  const [fieldToFocus, setFieldToFocus] = useState<string>(null);

  const location = useLocation<{ message?: string }>();

  const { selectedBU } = useSelectedBu();

  const emailsOptions = useMemo((): IDropdownOption[] => {
    const contacts = account?.contacts ?? contract.account?.contacts;

    if (!contacts) {
      return [];
    }

    return contacts
      .filter(contact => contact.contactType === AccountContactType.EMAIL)
      .map(contact => ({
        key: contact.id,
        text: contact.contact,
        id: `option-contact-${contact.id}`
      }));
  }, [account, contract]);

  const addressesOptions = useMemo((): IDropdownOption[] => {
    const addresses = account?.addresses ?? contract.account?.addresses;

    if (!addresses) {
      return [];
    }

    return addresses.map(address => ({
      key: address.id,
      text: `${address.street}, ${address.number ?? ""}${
        address.note ? ` - ${address.note}` : ""
      }, ${address.city} - ${address.state}`,
      id: `option-address-${address.id}`
    }));
  }, [account, contract]);

  const checkingAccountsOptions = useMemo((): IDropdownOption[] => {
    const checkingAccounts =
      account?.checkingAccounts ?? contract.account?.checkingAccounts;

    if (!checkingAccounts) {
      return [];
    }

    return checkingAccounts.map(checkingAccount => ({
      key: checkingAccount.id,
      text: `Banco ${checkingAccount?.bankNumber} Agência ${checkingAccount.agencyNumber} C/C ${checkingAccount.accountNumber}`,
      id: `option-account-${checkingAccount.id}`
    }));
  }, [account, contract]);

  const documentsOptions = useMemo((): IDropdownOption[] => {
    const documents = account?.documents ?? contract.account?.documents;

    if (!documents) {
      return [];
    }

    return documents.map(document => {
      if (document.documentType === "CPF") {
        return {
          key: document.id,
          text: document.documentNumber,
          id: `option-document-${document.id}`
        };
      }

      if (document.documentType === "CNPJ") {
        return {
          key: document.id,
          text: document.documentNumber,
          id: `option-document-${document.id}`
        };
      }
    });
  }, [account, contract]);

  useEffect(() => {
    if (location.state?.message) {
      setMessage({
        message: location.state.message,
        type: messageBarTypes.SUCCESS
      });
    }
  }, [location.state]);

  useEffect(() => {
    if (!account && !contract.account) {
      setInitialValues(undefined);
      return;
    }

    if (account === null) {
      setInitialValues(undefined);
      return;
    }

    if (account) {
      setSelectedCheckingAccount(checkingAccountsOptions[0]?.key);
      setSelectedEmail(emailsOptions[0].key);
      setSelectedAddress(addressesOptions[0].key);

      setInitialValues({
        account: account.id,
        accountDocument: documentsOptions[0].key,
        accountContact: emailsOptions[0].key,
        accountAddress: addressesOptions[0].key,
        checkingAccount: checkingAccountsOptions[0]?.key ?? null
      });

      return;
    }

    if (contract.account) {
      setSelectedCheckingAccount(contract.checkingAccount?.id);
      setSelectedEmail(contract.accountContact.id);
      setSelectedAddress(contract.accountAddress.id);

      setInitialValues({
        account: contract.account.id,
        accountDocument: contract.accountDocument.id,
        accountContact: contract.accountContact.id,
        accountAddress: contract.accountAddress.id,
        checkingAccount: contract.checkingAccount?.id ?? null
      });
    }
  }, [
    addressesOptions,
    checkingAccountsOptions,
    documentsOptions,
    emailsOptions,
    account,
    contract.account,
    contract.accountAddress,
    contract.accountContact,
    contract.accountDocument,
    contract.checkingAccount
  ]);

  return (
    <Stack
      tokens={{ childrenGap: 20 }}
      onClick={() => {
        setContractMessage(undefined);
      }}
    >
      <ShowMessageCard
        message={message}
        cleanMessage={() => setMessage(undefined)}
      />
      <Card>
        <StyledStep>
          <SearchClient
            setUpperAccount={setAccount}
            currentBU={contract.businessUnit.id}
            contract={contract}
            message={message}
            setMessage={setMessage}
            isAccountIncomplete={isAccountIncomplete}
            isEditingAccount={isEditingAccount}
            fieldToFocus={fieldToFocus}
            setIsAccountIncomplete={setIsAccountIncomplete}
            setIsEditingAccount={setIsEditingAccount}
            setFieldToFocus={setFieldToFocus}
          />
        </StyledStep>
      </Card>

      {initialValues && !isAccountIncomplete && (
        <FormStepTwo
          addressesOptions={addressesOptions}
          checkingAccountsOptions={checkingAccountsOptions}
          contract={contract}
          emailsOptions={emailsOptions}
          initialValues={initialValues}
          message={message}
          nextStep={props.nextStep}
          // TODO: precisa usar a BU do contrato (API não retorna paymentMethod)
          paymentMethod={selectedBU?.paymentMethod}
          selectedAddress={selectedAddress}
          selectedCheckingAccount={selectedCheckingAccount}
          selectedEmail={selectedEmail}
          setFieldToFocus={setFieldToFocus}
          setIsAccountIncomplete={setIsAccountIncomplete}
          setIsEditingAccount={setIsEditingAccount}
          setMessage={setMessage}
          setSelectedAddress={setSelectedAddress}
          setSelectedCheckingAccount={setSelectedCheckingAccount}
          setSelectedEmail={setSelectedEmail}
        />
      )}
    </Stack>
  );
}
