import {
  ChoiceGroup,
  DefaultButton,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";

import { fields as fieldsAccountLegal } from "../../fields/AccountsLegal.fields";
import { fields as fieldsAccountPhisycal } from "../../fields/AccountsPhysical.fields";

import { CustomDrawerFooter } from "@/components/Shared/CustomDrawer";
import { useGetProfile } from "@/core/libs/api/react-query";
import useGetLocations from "@/core/libs/api/react-query/hook/use-get-locations";
import { useApi } from "@/core/libs/api/react-query/useApi";
import FormBuilder from "@/core/libs/form-builder";
import { useSelectedBu } from "@/hooks/useSelectedBu";
import {
  getLegalAccountSchema,
  getPhysicalAccountSchema
} from "@/modules/Accounts/schemas/accounts.schema";
import {
  AccountDocumentType,
  AccountType,
  CreateProps
} from "@/modules/Accounts/types/Accounts.types";
import { PaymentMethod } from "@/modules/Contracts/enums";
import { ValidateCNPJ } from "@/utils/ValidateCNPJ";
import { ValidateCPF } from "@/utils/ValidateCPF";

const getChoices = (accountType: AccountType) => {
  if (!accountType) return choices;

  return choices.filter(ch => ch.key === accountType);
};
const choices = [
  {
    key: AccountType.PHYSICAL,
    text: "Pessoa Física",
    iconProps: { iconName: "Contact" },
    styles: {
      root: {
        display: "flex",
        flexDirection: "row !important",
        justifyContent: "center",
        background: "#00427a13",
        borderRadius: "5px",
        width: "35%",
        height: "50px",
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 10px 20px, rgba(0, 0, 0, 0.05) 0px 6px 6px"
      },
      labelWrapper: {
        color: "#00427c",
        fontWeight: "500",
        fontSize: "18px",
        minWidth: "70%",
        height: "fit-content",
        display: "flex"
      },
      field: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "#00427c",
        borderRadius: "5px",
        paddingTop: "0px"
      },
      iconWrapper: {
        color: "#00427c !important",
        fontSize: "25px"
      },
      innerField: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: "50px"
      },

      choiceFieldWrapper: {
        borderRadius: "5px",
        width: "100%",
        height: "100%"
      }
    }
  },
  {
    key: AccountType.LEGAL,
    text: "Pessoa Jurídica",
    iconProps: { iconName: "CityNext" },

    styles: {
      root: {
        display: "flex",
        flexDirection: "row !important",
        justifyContent: "center",
        background: "#28ee0013",
        borderRadius: "5px",
        width: "35%",
        height: "50px",
        marginLeft: "10px",
        boxShadow:
          "rgba(0, 0, 0, 0.05) 0px 10px 20px, rgba(0, 0, 0, 0.05) 0px 6px 6px"
      },
      labelWrapper: {
        color: "#107c10",
        fontWeight: "500",
        fontSize: "18px",
        minWidth: "70%",
        height: "fit-content",
        display: "flex"
      },
      field: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        color: "#107c10",
        borderRadius: "5px",
        paddingTop: "0px"
      },
      iconWrapper: {
        color: "#107c10",
        fontSize: "25px"
      },
      innerField: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: "35px"
      },

      choiceFieldWrapper: {
        // border: "1px solid #107c10",
        borderRadius: "5px",
        width: "100%",
        height: "100%"
      }
    }
  }
];

export function CreateAccount({
  create,
  closeDrawer,
  dismissMessage,
  drawerContainerRef,
  message,
  document,
  maritalStatusOptions
}: CreateProps): JSX.Element {
  const formId = "create-account-form";

  const [accountType, setAccountType] = useState<AccountType>();
  const [documentType, setDocumentType] = useState<AccountDocumentType>();
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [salesTeamList, setSalesTeamsList] = useState([]);

  const { selectedBU } = useSelectedBu();
  const { execute } = useApi();
  const userProfile = useGetProfile();
  const { cities, states, onStateChange } = useGetLocations();
  const permissions = userProfile.data?.data?.role?.permissions;

  let isSubmitted = false;
  if (message && message.type === "success") isSubmitted = true;

  function handleCloseDrawer() {
    closeDrawer();
    dismissMessage();
    setAccountType(undefined);
  }

  useEffect(() => {
    if (document) {
      const accountTypeVerify = ValidateCPF(document)
        ? AccountType.PHYSICAL
        : ValidateCNPJ(document)
        ? AccountType.LEGAL
        : undefined;
      if (accountTypeVerify) {
        setAccountType(accountTypeVerify);
      }
      if (ValidateCPF(document)) setDocumentType(AccountDocumentType.CPF);
      if (ValidateCNPJ(document)) setDocumentType(AccountDocumentType.CNPJ);
    }
  }, [document]);

  async function submitForm(values: any, { setSubmitting }) {
    setSubmitting(true);
    const commonObject = {
      documents: values?.documents ?? values?.documentsLegal,
      contacts: values?.contacts,
      checkingAccounts: values?.checkingAccounts,
      checkingAccountsGroupedFields: values?.salesTeam,
      legalRepresentatives: values?.legalRepresentatives || [],
      addresses: values?.addresses.map(address => ({
        ...address,
        state: address.state.replace(/[^a-zA-Z0-9]/g, "")
      })),
      salesTeamId: values?.accountSalesTeam,
      accountType
    };

    if (accountType === AccountType.PHYSICAL) {
      const stateFound = states?.find(state => state.key == values?.state);

      const naturalness = [
        {
          state: stateFound?.text,
          city: values.city
        }
      ];

      values?.naturalness &&
        Object.assign(commonObject, {
          ...commonObject,
          naturalness
        });
    }

    if (commonObject.legalRepresentatives) {
      commonObject.legalRepresentatives = commonObject.legalRepresentatives.map(
        rep => {
          if (rep.whatsapp) {
            rep.whatsapp = rep.whatsapp.replace(/\D/g, "");
          }
          return rep;
        }
      );
    }

    values?.account &&
      Object.assign(commonObject, {
        ...commonObject,
        ...values.account[0],
        fullName: values.account[0].fullName
      });
    values?.accountLegal &&
      Object.assign(commonObject, {
        ...commonObject,
        ...values.accountLegal[0],
        fullName: values.accountLegal[0].fullName
      });

    const result = await create(
      commonObject,
      { setSubmitting },
      handleCloseDrawer
    );
    if (!result.response.ok) {
      setErrorMessage(result?.error.message);
    }
    setSubmitting(false);
  }

  const getSalesTeamsFromApi = useCallback(async () => {
    const res = await execute({
      url: "sales-teams",
      notificationMessage:
        "Buscando times comerciais na página de criação de cooperado"
    });

    let options = [];

    if (res.data) {
      options = res.data.items.map(option => {
        return {
          key: option.id,
          text: `${option.name} ${
            option.parent?.name ? `(${option.parent.name})` : ""
          }`
        };
      });
    }

    setSalesTeamsList(options);
  }, [execute]);

  useEffect(() => {
    dismissMessage();
    getSalesTeamsFromApi();
  }, []);

  return (
    <>
      <Stack>
        <ChoiceGroup
          options={getChoices(accountType)}
          onChange={(_, item) => {
            if (!accountType) setAccountType(item?.key as AccountType);
          }}
          radioGroup="none"
          styles={{
            root: {
              overflow: "hidden",
              width: "100%"
            },

            flexContainer: {
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              paddingBottom: "15px"
            }
          }}
          disabled={!!document}
          selectedKey={accountType ? accountType : ""}
        />
        {accountType && (
          <FormBuilder
            formId={formId}
            fields={
              accountType === AccountType.PHYSICAL
                ? fieldsAccountPhisycal.create({
                    maritalStatusOptions: maritalStatusOptions,
                    bankNumberValue: selectedBU?.bankNumber,
                    agencyNumberValue: selectedBU?.agencyNumber,
                    executeOnChange: onStateChange,
                    states,
                    cities,
                    bankAccountRequired:
                      selectedBU?.paymentMethod ===
                      PaymentMethod.DEBITO_AUTOMATICO,
                    initialValues: document
                      ? {
                          documents: document
                            ? [
                                {
                                  documentNumber: document,
                                  documentType: documentType
                                },
                                {
                                  documentType: AccountDocumentType.RG,
                                  documentNumber: ""
                                }
                              ]
                            : undefined
                        }
                      : {},
                    salesTeamList: salesTeamList || [],
                    permissions: permissions ?? []
                  })
                : fieldsAccountLegal.create({
                    bankNumberValue: selectedBU?.bankNumber,
                    agencyNumberValue: selectedBU?.agencyNumber,
                    requireWhatsappForLegalRepresentative: true,
                    bankAccountRequired:
                      selectedBU?.paymentMethod ===
                      PaymentMethod.DEBITO_AUTOMATICO,
                    initialValues: document
                      ? {
                          documents: document
                            ? [
                                {
                                  documentNumber: document,
                                  documentType: documentType
                                },
                                {
                                  documentType: AccountDocumentType.RG,
                                  documentNumber: ""
                                }
                              ]
                            : undefined
                        }
                      : {},
                    salesTeamList: salesTeamList || [],
                    permissions: permissions ?? []
                  })
            }
            onSubmit={submitForm}
            message={message}
            dismissMessage={dismissMessage}
            dismissPanel={handleCloseDrawer}
            validationSchema={
              accountType === AccountType.PHYSICAL
                ? getPhysicalAccountSchema({
                    checkingAccountsRequired:
                      selectedBU?.paymentMethod ===
                      PaymentMethod.DEBITO_AUTOMATICO
                  })
                : getLegalAccountSchema({
                    checkingAccountsRequired:
                      selectedBU?.paymentMethod ===
                      PaymentMethod.DEBITO_AUTOMATICO
                  })
            }
          >
            <CustomDrawerFooter drawerContainerRef={drawerContainerRef}>
              {errorMessage && (
                <MessageBar messageBarType={MessageBarType.error} isMultiline>
                  {errorMessage}
                </MessageBar>
              )}
              <DefaultButton
                id="close-account-drawer-button"
                onClick={handleCloseDrawer}
                style={{ margin: "12px" }}
              >
                {!isSubmitted ? "Cancelar" : "Fechar"}
              </DefaultButton>

              <PrimaryButton
                id="create-account-button"
                type="primary"
                form={formId}
                disabled={isSubmitted}
              >
                Criar
              </PrimaryButton>
            </CustomDrawerFooter>
          </FormBuilder>
        )}
      </Stack>
    </>
  );
}
