import { ICommandBarItemProps } from "@fluentui/react";

import { checkUserPermission } from "./checkUserPermissions";

import {
  PermissionsAction,
  PermissionsPosModule,
  PermissionsScope
} from "@/common/types/permissions";

export const buildCommandBarItems = ({
  term,
  downloadContract,
  permissions,
  setOccurrencesDrawerVisible,
  advanceStep,
  cancelOrRejectTerm
}) => {
  const termsPermissions =
    permissions[PermissionsScope.POS]?.[PermissionsPosModule.TERMS];

  const changeStatusOptions = [];
  const currentStep = term?.currentStep;
  const rejectedAction = term?.approvalFlow?.steps.find(
    step => step.internalName === "REJECTED"
  );
  const canceledAction = term?.approvalFlow?.steps.find(
    step => step.internalName === "CANCELED"
  );

  const userHasPermission = checkUserPermission({
    permissions,
    scope: PermissionsScope.POS,
    module: PermissionsPosModule.TERMS,
    action: term?.nextStep?.interactionAction
  });

  //ADVANCE STEP OPTION
  if (currentStep?.interactionType === "ACTION_STATUS" && userHasPermission) {
    changeStatusOptions.push({
      key: currentStep.id,
      text: currentStep.interactionName,
      onClick: () => advanceStep(),
      id: `currentStep-${currentStep?.internalName}-button`.replace(/\s/g, "")
    });
  }

  //CANCEL ACTION OPTION
  if (
    canceledAction &&
    term?.nextStep &&
    termsPermissions?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === canceledAction?.interactionAction
    )
  ) {
    changeStatusOptions.push({
      key: canceledAction?.id,
      text: canceledAction?.interactionName,
      onClick: () => cancelOrRejectTerm("cancel"),
      id: `nextStep-${canceledAction?.internalName}-button`.replace(/\s/g, "-")
    });
  }

  //REJECT ACTION OPTION
  if (
    rejectedAction &&
    term?.nextStep &&
    termsPermissions?.some(
      contractAction =>
        contractAction === PermissionsAction.ALL ||
        contractAction === rejectedAction?.interactionAction
    )
  ) {
    changeStatusOptions.push({
      key: rejectedAction?.id,
      text: rejectedAction?.interactionName,
      onClick: () => cancelOrRejectTerm("reject"),
      id: `nextStep-${rejectedAction?.internalName}-button`.replace(/\s/g, "-")
    });
  }

  const items: ICommandBarItemProps[] = [
    {
      id: "button-print",
      key: "Imprimir",
      text: "Imprimir",
      iconProps: { iconName: "Print" },
      onClick: () => downloadContract(),
      disabled: !term?.pdfPath
    },
    {
      id: "button-change-status",
      key: "status",
      text: "Alterar status",
      iconProps: { iconName: "Sync" },
      subMenuProps: { items: changeStatusOptions },
      disabled: changeStatusOptions.length === 0
    },
    {
      id: "button-contract-history",
      key: "contractOccurrences",
      text: "Histórico do termo",
      disabled: true,
      iconProps: { iconName: "FullHistory" },
      onClick: () => setOccurrencesDrawerVisible(true)
    }
  ];

  return items;
};
