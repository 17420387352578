import { grey } from "@ant-design/colors";
import { Icon } from "@fluentui/react";
import { Space, Typography } from "antd";

import { DefaultCardTitle } from "../styles";

import { PaymentInfoList } from "./PaymentInfoList";

import { Card } from "@/components/Shared/Card";
import { ActivationFeePaymentInfo } from "@/modules/User/types";

const { Paragraph } = Typography;

type ContractActivationFeePaymentProps = {
  contractActivationFee: string | number;
  activationFeePaymentInfo: ActivationFeePaymentInfo[];
};

function toLocaleString(value: string | number) {
  return Number(value).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
}

export function ContractActivationFeePayment({
  contractActivationFee,
  activationFeePaymentInfo
}: ContractActivationFeePaymentProps): JSX.Element {
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <DefaultCardTitle level={4}>
        <Icon
          iconName="Bank"
          style={{ marginRight: 10, position: "relative", top: 1 }}
        />
        Pagamento da adesão
      </DefaultCardTitle>

      <Card>
        <Space
          direction="vertical"
          size="large"
          style={{ padding: 20, width: "100%" }}
        >
          <Paragraph
            style={{ fontSize: 18, margin: "unset", color: grey.primary }}
          >
            Lembre-se de enviar o comprovante do depósito, transferência
            identificada ou PIX para prosseguirmos com a liberação dos
            benefícios.
          </Paragraph>
          <Paragraph
            style={{ fontSize: 18, margin: "unset", color: grey.primary }}
          >
            O valor é de {toLocaleString(contractActivationFee)} e deve ser
            realizado para:
          </Paragraph>

          <PaymentInfoList
            activationFeePaymentInfo={activationFeePaymentInfo}
          />
        </Space>
      </Card>
    </div>
  );
}
