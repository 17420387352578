import { Icon, ITheme, Stack, TooltipHost } from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

import {
  AdditionalPriceInfo,
  CurrencySymbol,
  CustomSeparator,
  DevicePrice,
  PlanPrice,
  PlansAppsInfoList,
  PriceContainer,
  PriceTitle,
  ProductAppIcon,
  ProductBrandLogo,
  ProductItem,
  ProductPlanName,
  ProductPricing,
  ProductRow,
  TotalPrice
} from "./ProductPlans.styles";

import cabifyIcon from "@/assets/images/plan-apps/cabify.svg";
import easyTaxiIcon from "@/assets/images/plan-apps/easy-taxi.svg";
import facebookIcon from "@/assets/images/plan-apps/facebook.svg";
import instagramIcon from "@/assets/images/plan-apps/instagram.svg";
import messengerIcon from "@/assets/images/plan-apps/messenger.svg";
import telegramIcon from "@/assets/images/plan-apps/telegram.svg";
import wazeIcon from "@/assets/images/plan-apps/waze.svg";
import whatsappIcon from "@/assets/images/plan-apps/whatsapp.svg";
import { AdditionalListContent } from "@/components/Shared/OffersToolTip/AdditionalListContent";
import { SaleCondition } from "@/modules/Offers/types/ProductsOffers.types";
import { OfferCombo } from "@/modules/Settings/types/Offers.types";
import { convertCurrencyStringToNumber } from "@/utils/ConvertCurrencyStringToNumber";

type ProductPlanItemProps = {
  item: SaleCondition;
  theme: ITheme;
  renderAdditionals?: boolean;
};

export const productAppIcons = {
  WHATSAPP: whatsappIcon,
  WAZE: wazeIcon,
  EASY_TAXI: easyTaxiIcon,
  CABIFY: cabifyIcon,
  FACEBOOK: facebookIcon,
  INSTAGRAM: instagramIcon,
  MESSENGER: messengerIcon,
  TELEGRAM: telegramIcon
};

export function ProductPlanItem({
  item,
  theme,
  renderAdditionals
}: ProductPlanItemProps): JSX.Element {
  const tooltipId = useId("tooltip");
  const isSpecialOffer =
    (item.offerPrice?.offer?.plan?.name?.search(/especial/i) !== -1 ||
      item.offerPrice?.offer?.plan?.name?.search(/black friday 2021/i) !==
        -1) &&
    item?.offerPrice?.offer?.combo === OfferCombo.SMART;

  return (
    <Stack
      verticalAlign="center"
      style={{ width: "100%", marginRight: 10, marginTop: 10 }}
    >
      <ProductRow>
        <div>
          <ProductBrandLogo
            src={item?.offerPrice?.offer?.plan?.carrier?.logo}
            alt={item?.carrier}
          />

          <ProductPlanName
            isSpecial={isSpecialOffer}
            className="ms-fontSize-20 ms-fontWeight-bold"
          >
            {isSpecialOffer ? "** " : ""} {item?.offerPrice?.offer?.plan?.name}
          </ProductPlanName>
        </div>

        {item?.offerPrice?.offer?.plan?.freeDataApps?.length > 0 && (
          <div style={{ marginLeft: 10 }}>
            {item.offerPrice?.offer?.plan?.appsInfos?.length > 0 && (
              <TooltipHost
                tooltipProps={{
                  onRenderContent: () => (
                    <PlansAppsInfoList>
                      {item.offerPrice?.offer?.plan?.appsInfos?.map(
                        (info, index) => (
                          <li
                            className="ms-fontWeight-semibold"
                            key={"infoApp" + index}
                          >
                            {info}
                          </li>
                        )
                      )}
                    </PlansAppsInfoList>
                  )
                }}
                id={tooltipId}
                styles={{ root: { position: "relative", bottom: "10%" } }}
              >
                <Icon
                  iconName="Info"
                  aria-describedby={tooltipId}
                  style={{
                    fontSize: 16
                  }}
                />
              </TooltipHost>
            )}

            {item?.offerPrice?.offer?.plan?.freeDataApps.map(app => (
              <ProductAppIcon
                src={productAppIcons[app]}
                alt={app + " ICON APP"}
                key={app}
              />
            ))}
          </div>
        )}
      </ProductRow>

      <ProductRow>
        <div>
          {isSpecialOffer && (
            <ProductItem className="ms-fontSize-16">
              <Icon
                iconName="Info"
                style={{
                  fontSize: 18,
                  marginRight: 15
                }}
              />
              <span
                style={{
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  color: theme.palette.red
                }}
              >
                ** Atenção! Este benefício possui condições especiais para
                validação. Consulte o Book de Benefícios vigente para mais
                detalhes.
              </span>
            </ProductItem>
          )}

          {item?.offerPrice?.offer?.plan?.voice && (
            <ProductItem className="ms-fontSize-16">
              <Icon
                iconName="Phone"
                style={{
                  fontSize: 18,
                  marginRight: 15
                }}
              />
              <span
                style={{
                  maxWidth: "100%",
                  wordWrap: "break-word",
                  overflowWrap: "break-word"
                }}
              >
                {item.offerPrice.offer.plan.voice}
              </span>
            </ProductItem>
          )}

          <ProductItem className="ms-fontSize-16">
            <Icon
              iconName="Globe"
              style={{
                fontSize: 18,
                marginRight: 15
              }}
            />
            <span
              style={{
                maxWidth: "100%",
                wordWrap: "break-word",
                overflowWrap: "break-word"
              }}
            >
              {Number(item?.offerPrice?.offer?.plan?.data) ||
                0 + Number(item?.offerPrice?.offer?.bonusData?.value) ||
                0}{" "}
              GB de internet móvel
            </span>

            {item?.offerPrice?.offer?.bonusData?.value && (
              <span>
                &nbsp;+ bônus de{" "}
                {Number(item?.offerPrice?.offer?.bonusData?.value)} GB por{" "}
                {Number(item?.offerPrice?.offer?.bonusData?.numberOfMonths)}{" "}
                meses
              </span>
            )}
          </ProductItem>

          <ProductItem minWidth="200px" className="ms-fontSize-16">
            <Icon
              iconName="Money"
              style={{
                fontSize: 18,
                marginRight: 15
              }}
            />
            <span>
              {item?.offerPrice?.offer?.discount?.value ? "* " : null}
              Pacote de Benefício no valor de R$ {toLocaleString(item?.offerPrice?.price)}
              {item?.offerPrice?.offer?.discount?.value
                ? ` com desconto de R$ ${toLocaleString(
                    item.offerPrice.offer.discount.value
                  )}`
                : null}
              {item?.offerPrice?.offer?.discount?.numberOfMonths
                ? ` por ${item.offerPrice.offer.discount.numberOfMonths} meses a partir da`
                : null}
              {item?.offerPrice?.offer?.discount?.beginsAt
                ? ` ${item.offerPrice.offer.discount.beginsAt}.ª fatura`
                : null}
              {item?.offerPrice?.offer?.activationFee
                ? ` e valor a ser cobrado
                de ativação de ${toLocaleString(
                  item.offerPrice.offer.activationFee
                )}`
                : null}
            </span>
          </ProductItem>
          {item?.offerPrice?.additionalOfferPrice.length > 0 &&
            renderAdditionals && (
              <ProductItem className="ms-fontSize-16">
                <Icon
                  iconName="CircleAddition"
                  style={{
                    fontSize: 18,
                    marginRight: 15
                  }}
                />
                <span className="additional-title">
                  <strong>Atividades de valor agregado</strong>
                  <AdditionalListContent
                    additionals={item?.offerPrice?.additionalOfferPrice}
                  />
                </span>
              </ProductItem>
            )}
        </div>

        <ProductPricing>
          <PlanPrice>
            <PriceTitle className="ms-fontSize-16 ms-fontWeight-bold">
              Plano de benefícios
            </PriceTitle>

            <PriceContainer>
              <div>
                <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                  R$
                </CurrencySymbol>{" "}
                <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                  {toLocaleString(
                    convertCurrencyStringToNumber(item.offerPrice?.price) -
                      convertCurrencyStringToNumber(
                        item.offerPrice?.offer?.discount?.value || "0"
                      )
                  )}
                  {item.offerPrice?.offer?.discount?.value ? "*" : null}
                </TotalPrice>
              </div>

              <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                mensal
              </AdditionalPriceInfo>
            </PriceContainer>
          </PlanPrice>

          <Stack.Item styles={{ root: { height: 100 } }}>
            <CustomSeparator>
              <Icon
                iconName="Add"
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: theme.palette.themeTertiary,
                  marginTop: 10,
                  marginBottom: 10
                }}
              />
            </CustomSeparator>
          </Stack.Item>

          <DevicePrice>
            <PriceTitle className="ms-fontSize-16 ms-fontWeight-bold">
              Aparelho
            </PriceTitle>

            {Number(item?.cashValue) > 0 && (
              <>
                <PriceContainer>
                  <div>
                    <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                      R$
                    </CurrencySymbol>{" "}
                    <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                      {toLocaleString(item?.cashValue)}
                    </TotalPrice>
                  </div>
                  <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                    à vista
                  </AdditionalPriceInfo>
                </PriceContainer>

                <PriceContainer>
                  <div>
                    <CurrencySymbol className="ms-fontSize-10 ms-fontWeight-bold">
                      R$
                    </CurrencySymbol>{" "}
                    <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                      {toLocaleString(Number(item?.financedValue) / 24)}
                    </TotalPrice>
                  </div>

                  <AdditionalPriceInfo className="ms-fontSize-12 ms-fontWeight-semibold">
                    1 + 23X
                  </AdditionalPriceInfo>
                </PriceContainer>
              </>
            )}

            {Number(item?.cashValue) <= 0 && (
              <PriceContainer>
                <div>
                  <TotalPrice className="ms-fontSize-20 ms-fontWeight-bold">
                    GRÁTIS
                  </TotalPrice>
                </div>
              </PriceContainer>
            )}
          </DevicePrice>
        </ProductPricing>
      </ProductRow>
    </Stack>
  );
}

function toLocaleString(value) {
  return Number(value).toLocaleString("pt-BR", {
    minimumFractionDigits: 2
  });
}
