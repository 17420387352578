import { IColumn } from "@fluentui/react";

import { FiltersType } from "../enums";
import { ListDataMassActionsTypes } from "../types";

import {
  InventoryAvailabilityEnum,
  InventoryAvailabilityTranslatedEnum
} from "@/modules/Settings/types/Inventories.types";
import {
  CarrierStatus,
  LineStatus
} from "@/modules/Settings/types/Lines.types";

export type IMassActionFields = {
  key: number;
  fieldName: string;
  field: string;
  type: FiltersType;
  options?: Array<{ key: any; text: any }>;
  disabled?: boolean;
};

const availabilityOptions = [
  {
    key: InventoryAvailabilityEnum.RESERVED,
    text: InventoryAvailabilityTranslatedEnum.RESERVED
  },
  {
    key: InventoryAvailabilityEnum.WITHDRAWN,
    text: InventoryAvailabilityTranslatedEnum.WITHDRAWN
  },
  {
    key: InventoryAvailabilityEnum.AVAILABLE,
    text: InventoryAvailabilityTranslatedEnum.AVAILABLE
  },
  {
    key: InventoryAvailabilityEnum.PENDING,
    text: InventoryAvailabilityTranslatedEnum.PENDING
  },
  {
    key: InventoryAvailabilityEnum.SOLD,
    text: InventoryAvailabilityTranslatedEnum.SOLD
  }
];

const valueInventoryColumnFinder = (data: any, key: string) => {
  switch (key) {
    case "distributionCenter":
      return data.distributionCenter?.id;
    case "status":
      return data.status;
    case "availability":
      return data.availability;
    default:
  }
};

const valueLineColumnFinder = (data: any, key: string) => {
  switch (key) {
    case "lineStatus":
      return data.lineStatus;
    case "carrierStatus":
      return data.carrierStatus;
    case "carrier":
      return data.carrier.name;
    default:
  }
};
const InventoryColumnTranslator = (key: string) => {
  switch (key) {
    case "distributionCenter":
      return "Centro de distribuição";
    case "status":
      return "Status";
    case "availability":
      return "Disponibilidade";
    case "new-distributionCenter":
      return "Novo Centro de distribuição";
    case "new-status":
      return "Novo Status";
    case "new-availability":
      return "Nova Disponibilidade";
    default:
  }
};

const LineColumnTranslator = (key: string) => {
  switch (key) {
    case "lineStatus":
      return "Status da linha";
    case "carrierStatus":
      return "Status na operadora";
    case "carrier":
      return "Operadora";
    case "new-lineStatus":
      return "Novo Status da linha";
    case "new-carrierStatus":
      return "Novo Status na operadora";
    case "new-carrier":
      return "Nova Operadora";
    default:
  }
};
export const keysTransform = (fieldsSelected: { [key: string]: any }) => {
  const keys = [];
  for (const k of Object.keys(fieldsSelected)) {
    keys.push(k);
    keys.push("new-" + k);
  }
  return keys;
};

export const inventoryMassActionFields = (configs: {
  options?: { distributionCenterOptions?: Array<{ key: any; text: any }> };
}) => [
  {
    key: 1,
    fieldName: "Status",
    field: "status",
    type: FiltersType.SELECT,
    options: [
      { key: "true", text: "Ativo" },
      { key: "false", text: "Inativo" }
    ],
    disabled: true
  },
  {
    key: 2,
    fieldName: "Nova Disponibilidade",
    field: "availability",
    type: FiltersType.SELECT,
    options: availabilityOptions,
    disabled: true
  },
  {
    key: 3,
    fieldName: "Novo Centro de Distribuição",
    field: "distributionCenter",
    type: FiltersType.SELECT,
    options: configs?.options?.distributionCenterOptions ?? [],
    disabled: true
  }
];

export const linesActionFields = (configs: {
  options?: { carrierOptions?: Array<{ key: any; text: any }> };
}) => {
  return [
    {
      key: 1,
      fieldName: "Status da linha",
      field: "lineStatus",
      type: FiltersType.SELECT,
      options: [
        { key: LineStatus.ACTIVE, text: "Ativa" },
        { key: LineStatus.RESERVED, text: "Reservada" },
        { key: LineStatus.SOLD, text: "Vendida" }
      ],
      disabled: true
    },
    {
      key: 2,
      fieldName: "Status na operadora",
      field: "carrierStatus",
      type: FiltersType.SELECT,
      options: [
        { key: CarrierStatus.ACTIVE, text: "Ativa" },
        { key: CarrierStatus.SUSPENDED, text: "Suspensa" }
      ],
      disabled: true
    },
    {
      key: 3,
      fieldName: "Nova Operadora",
      field: "carrier",
      type: FiltersType.SELECT,
      options: configs?.options.carrierOptions ?? [],
      disabled: true
    }
  ];
};

const valueOfferColumnFinder = (data: any, key: string) => {
  switch (key) {
    case "offerPriceId":
      return data.offerPrices.map((op, idx) => {
        const opId = op.id.toString();
        if (data.offerPrices[idx + 1]) return opId + " • ";
        return opId;
      });
    case "validUntil":
      return data.offerPrices
        .filter(op => op.validBetween !== "empty" || !op.validBetween)
        .map((op, idx) => {
          const finalValid = op.validBetween.split(",")[1].split(")")[0];

          if (data.offerPrices[idx + 1]) return finalValid + " • ";
          return finalValid;
        });

    default:
  }
};
const OfferColumnTranslator = (key: string) => {
  switch (key) {
    case "offerPriceId":
      return "Ids Precificação do pacote de benefícios";
    case "validUntil":
      return "Validades Finais";
    case "new-validUntil":
      return "Nova Validade Final";
    default:
  }
};
export const offersMassActionFields = [
  {
    key: 1,
    fieldName: "Validade Final",
    field: "validUntil",
    type: FiltersType.DATE,
    disabled: true
  }
];

export const buildModalColumns = (
  fieldsSelected: { [key: string]: any },
  massActionsType: ListDataMassActionsTypes
) => {
  const keys = keysTransform(fieldsSelected);

  switch (massActionsType) {
    case ListDataMassActionsTypes.INVENTORY:
      return (() => {
        const columns: Array<IColumn> = keys.map(key => ({
          key: key,
          name: InventoryColumnTranslator(key),
          fieldName: key,
          isResizable: true,
          minWidth: 120
        }));
        columns.splice(0, 0, {
          key: "Id de Inventario",
          name: "Id de Inventário",
          fieldName: "inventoryId",
          isResizable: true,
          maxWidth: 120,
          minWidth: 120
        });
        return columns;
      })();
    case ListDataMassActionsTypes.OFFER:
      return (() => {
        if (keys.find(k => k === "validUntil")) {
          keys.splice(0, 0, "offerPriceId");
        }
        const columns: Array<IColumn> = keys.map(key => ({
          key: key,
          name: OfferColumnTranslator(key),
          fieldName: key,
          isResizable: true,
          isMultiline: true,
          minWidth: 140
        }));
        columns.splice(0, 0, {
          key: "Id do Benefício",
          name: "Id do Benefício",
          fieldName: "offerId",
          isResizable: true,
          maxWidth: 120,
          minWidth: 120
        });
        return columns;
      })();
    case ListDataMassActionsTypes.LINE:
      return (() => {
        const columns: Array<IColumn> = keys.map(key => ({
          key: key,
          name: LineColumnTranslator(key),
          fieldName: key,
          isResizable: true,
          isMultiline: true,
          minWidth: 140
        }));
        columns.splice(0, 0, {
          key: "Id da Linha",
          name: "Id da Linha",
          fieldName: "lineId",
          isResizable: true,
          maxWidth: 120,
          minWidth: 120
        });
        return columns;
      })();
    default:
      break;
  }
};

export const populateModalColumns = (
  data: any,
  fieldsSelected: { [key: string]: any },
  massActionsType: ListDataMassActionsTypes
) => {
  const keys = keysTransform(fieldsSelected);
  const dataPopulated = [];
  switch (massActionsType) {
    case ListDataMassActionsTypes.INVENTORY:
      for (const key of keys) {
        Object.assign(dataPopulated, {
          inventoryId: data.id
        });
        if (key.includes("new")) {
          Object.assign(dataPopulated, {
            [key]: fieldsSelected[key.split("new-")[1]]
          });
        } else {
          Object.assign(dataPopulated, {
            [key]: valueInventoryColumnFinder(data, key)
          });
        }
      }
      return dataPopulated;
    case ListDataMassActionsTypes.OFFER:
      if (keys.find(k => k === "validUntil")) {
        keys.push("offerPriceId");
      }
      for (const key of keys) {
        Object.assign(dataPopulated, {
          offerId: data.id
        });
        if (key.includes("new")) {
          Object.assign(dataPopulated, {
            [key]: fieldsSelected[key.split("new-")[1]]
          });
        } else {
          Object.assign(dataPopulated, {
            [key]: valueOfferColumnFinder(data, key)
          });
        }
      }
      return dataPopulated;
    case ListDataMassActionsTypes.LINE:
      // eslint-disable-next-line no-case-declarations
      for (const key of keys) {
        Object.assign(dataPopulated, {
          lineId: data.id
        });
        if (key.includes("new")) {
          Object.assign(dataPopulated, {
            [key]: fieldsSelected[key.split("new-")[1]]
          });
        } else {
          Object.assign(dataPopulated, {
            [key]: valueLineColumnFinder(data, key)
          });
        }
      }
      return dataPopulated;
    default:
  }
};
