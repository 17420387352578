import { CommomListProps } from "@/common/types/ListFromApi.types";
import { IAdditionalOfferPrice } from "@/components/Settings/AdditionalComponents/types";
import { LineStrategyType } from "@/modules/Settings/pages/Operations/enum";
import { ProductSpecification } from "@/modules/Settings/types/Products.types";
import { ProductBrandItem } from "@/modules/Settings/types/ProductsBrands.types";

export enum ProductStep {
  FILTER = "filter",
  LIST_PLANS = "listPlans",
  CONTRACT = "contract"
}

export enum ProductContractType {
  NEW = "Novo",
  EXISTENT = "Existente"
}

export enum ProductContractOperation {
  LINHA_NOVA = "1",
  PORTABILIDADE = "2",
  TRANSFERENCIA = "3",
  RENOVACAO = "4"
}

export interface ProductsCarriers {
  id: number;
  name: string;
}

export interface ProductsBrands {
  id: number;
  name: string;
}

export interface ProductsCategory {
  id: number;
  name: string;
}

export type OperationTypes = {
  id: number;
  name: string;
  type: "STANDARD" | "RENEWAL" | "BRING_YOUR_OWN";
  serviceCategoryType: "MOBILE_LINE" | "BROADBAND" | "TRACKER" | "TAG";
  lineStrategy: LineStrategyType;
};

export interface Image {
  id: number;
  url: string;
  order: number;
}

export interface OfferPrice {
  id?: number;
  price?: string;
  offer?: Offer;
  additionalOfferPrice?: IAdditionalOfferPrice[];
}

export interface Offer {
  id?: number;
  discount?: Discount;
  activationFee?: string;
  combo?: string;
  plan?: Plan;
  simValue?: string;
  bonusData?: {
    beginsAt?: number;
    numberOfMonths?: number;
    value?: number;
  };
}

export interface Discount {
  beginsAt?: number;
  numberOfMonths?: number;
  value?: number;
}

export interface Plan {
  id?: number;
  carrier?: {
    id?: number;
    logo?: string;
    name?: string;
  };
  appsInfos?: string[];
  data?: string;
  freeDataApps?: string[];
  includedServices?: string[];
  name?: string;
  voice?: string;
  description?: string;
  service?: {
    id?: number;
    name?: string;
  };
}

export interface SaleCondition {
  id?: number;
  cashValue?: string;
  financedValue?: string;
  carrier?: string;
  summedData?: number;
  installmentValue?: string;
  offerPrice?: OfferPrice;
}

export type ProductOfferItem = {
  id?: number;
  name?: string;
  allCarriers?: string[];
  allCashValue?: string[];
  allData?: number[];
  specifications?: ProductSpecification;
  brand?: ProductBrandItem;
  images?: Image[];
  saleConditions?: SaleCondition[];
  inStock?: number;
};

export interface ListOfProducts extends CommomListProps {
  items?: ProductOfferItem[];
}

export interface ListOfProductsOffers extends CommomListProps {
  products: ListOfProducts;
  productsBrands: ProductsBrands[];
  productsCategories: ProductsCategory[];
  carriers: ProductsCarriers[];
}

export interface Carriers {
  id: number;
  status: boolean;
  name: string;
  logo: string;
  plans: number;
}
